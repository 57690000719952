import React, { useEffect, useMemo, useState } from 'react';
import {
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	Stack,
	Tooltip,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import {
	useSendPreRenewalEmailMutation,
	useSendScopeOfAdviceEmailMutation,
} from '@/features/deals/dealsApi';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { determineContext } from '@/hooks/determineContext';
import { showToast } from '@/features/toast/toastSlice';
import ConfirmEmailDialog from '@/components/dialogs/ConfirmEmailDialog';

export const PreRenewalEmailButton = () => {
	const dispatch = useDispatch();

	const [confirmationOpen, setConfirmationOpen] = useState(false);

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const userQuery = useGetUserDetailsQuery({ email }, { skip: !email });

	const { deal, dealRow } = determineContext();

	const disabled = useMemo(() => dealRow?.renewalDate == null, [dealRow?.renewalDate]);

	const [sendPreRenewalEmail, { isLoading }] = useSendPreRenewalEmailMutation();

	const handleSendPreRenewalEmail = async () => {
		await sendPreRenewalEmail({
			dealId: deal?.hs_object_id,
			hubspotId: userQuery?.data?.hubspotId,
		})
			.unwrap()
			.then((res) => {
				console.log('Result of sending email', res);
				dispatch(
					showToast({
						message: 'Email sent!',
						success: true,
					})
				);
			})
			.catch((err) => {
				console.log('Error sending email', err);
				dispatch(
					showToast({
						message: 'Error sending pre-renewal email; please try again.',
						error: true,
					})
				);
			});
	};

	return (
		<>
			<Tooltip title={disabled ? 'Please enter a renewal date' : null} >
				<Button
					disabled={isLoading || disabled || !deal || !userQuery.data}
					variant='tab_header'
					startIcon={<EmailIcon />}
					onClick={() => setConfirmationOpen(true)}
				>
					{'Email Pre-Renewal'}
				</Button>
			</Tooltip>

			{confirmationOpen && (
				<ConfirmEmailDialog
					{...{
						emailType: 'Pre-Renewal',
						confirmationOpen,
						setConfirmationOpen,
						email,
						handleSendEmail: handleSendPreRenewalEmail,
						emailExplanation: ' outlining the policy information due for renewal',
					}}
				/>
			)}
		</>
	);
};
