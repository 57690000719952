import { createSlice, current } from '@reduxjs/toolkit';

// A function to generate initialState
export const generateInitialState = (initialOrderBy = 'createdate', initialOrder = 'desc') => ({
	endAt: null,
	pageAfter: null,
	rowsPerPage: 30,
	openDialog: false,
	searchValue: '',
	searchText: '',
	orderBy: initialOrderBy,
	order: initialOrder,
	page: 0,
	rows: [],
	selected: [],
});

// Modify the slice to accept dynamic initial states
export const createTableSlice = (sliceName, initialState) =>
	createSlice({
		name: sliceName,
		initialState,
		reducers: {
			setPageAfter: (state, action) => {
				state.pageAfter = action.payload;
			},
			setRowsPerPage: (state, action) => {
				state.rowsPerPage = action.payload;
			},
			setOpenDialog: (state, action) => {
				state.openDialog = action.payload;
			},
			setSearchValue: (state, action) => {
				state.searchValue = action.payload;
			},
			setSearchText: (state, action) => {
				state.searchText = action.payload;
			},
			setOrderBy: (state, action) => {
				state.orderBy = action.payload;
			},
			setOrder: (state, action) => {
				state.order = action.payload;
			},
			setPage: (state, action) => {
				state.page = action.payload;
			},
			setRows: (state, action) => {
				state.rows = action.payload;
			},
			resetSearchFields: (state) => {
				state.searchValue = '';
				state.searchText = '';
				state.pageAfter = null;
				state.page = 0;
				state.rows = [];
			},
			resetPages: (state) => {
				state.pageAfter = null;
				state.page = 0;
			},
			changeRowsPerPage: (state, action) => {
				state.rowsPerPage = parseInt(action.payload, 10);
				state.pageAfter = null;
				state.endAt = null;
				state.page = 0;
			},
			requestSort: (state, action) => {
				console.log('here is the action: ', action);
				const property = action.payload;
				const isAsc = state.orderBy === property && state.order === 'asc';
				state.order = isAsc ? 'desc' : 'asc';
				state.orderBy = property;
			},
			changePage: (state, action) => {
				const { newPage, lastPageAfter } = action.payload;
				const newIndex = newPage * state.rowsPerPage;
				if (newPage === 0) {
					state.pageAfter = null;
				} else {
					state.pageAfter = newIndex;
				}
				state.page = newPage;
			},
			resetAllPages: (state) => {
				state.pageAfter = null;
				state.page = 0;
			},
			onOrderChange: (state) => {
				state.rows = [];
				state.pageAfter = null;
				state.page = 0;
			},
			setSelected: (state, action) => {
				const newValue = action.payload;
				if (Array.isArray(newValue)) {
					state.selected = newValue;
				} else {
					const currentValues = current(state.selected);
					if (currentValues.some(d => d.id == newValue.id)) {
						state.selected = currentValues.filter((f) => f.id != newValue.id);
					} else {
						state.selected = [...currentValues, newValue];
					}
				}
			},
		},
	});
