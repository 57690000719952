import React, { useState } from 'react';
import {
	IconButton,
	Menu,
	MenuList,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Tooltip,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { determineContext } from '@/hooks/determineContext';
import { ConfirmDialog } from '@/components/dialogs/ConfirmDialog';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';
import { useDeleteDealMutation } from '@/features/deals/dealsApi';
import { useNavigate } from 'react-router-dom';
import { getUrlFromPipeline } from '@/utils/constants';
import { useDispatch } from 'react-redux';
import { showToast } from '@/features/toast/toastSlice';

const DealMenu = ({ setIsEditing }) => {
	const { deal, dealRow, objectType } = determineContext();

	const hasDependentDeal = dealRow?.status != 'Not Started' || (dealRow?.nextDealId || dealRow?.nextDealRow);

	const dealId = deal?.id ?? deal?.hs_object_id;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [showDialog, setShowDialog] = useState(false);

	const [deleteDeal] = useDeleteDealMutation({
		fixedCacheKey: `delete-deal-${dealId}-${objectType}`,
	});

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDeleteConfirmation = async () => {
		setShowDialog(false);
		const path = getUrlFromPipeline(deal?.pipeline ?? deal?.hs_pipeline);

		await deleteDeal({ dealId, objectType })
			.unwrap()
			.then((res) => {
				dispatch(
					showToast({
						message: `Deal ${deal?.id ?? deal?.hs_object_id} successfully deleted.`,
						success: true,
					})
				);
				navigate(path);
			})
			.catch((err) => {
				console.log(`Error deleting deal ${deal?.id ?? deal?.hs_object_id}`, err);
				dispatch(
					showToast({
						message: 'Error deleting deal; please try again.',
						error: true,
					})
				);
			});
	};

	return (
		<>
			<IconButton
				id='deal-menu-button'
				aria-controls={open ? 'deal-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				id='deal-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'deal-menu-button',
				}}
			>
				<MenuItem
					onClick={() => {
						setIsEditing(true);
						handleClose();
					}}
				>
					<ListItemIcon>
						<EditIcon />
					</ListItemIcon>
					<ListItemText>Edit deal name</ListItemText>
				</MenuItem>
				<Tooltip
					title={
						hasDependentDeal
							? 'Cannot delete deal as it has a dependent deal (e.g. a renewal or endorsement)'
							: ''
					}
				>
					<MenuItem
						disabled={hasDependentDeal}
						onClick={() => {
							setShowDialog(true);
							handleClose();
						}}
					>
						<ListItemIcon>
							<Delete />
						</ListItemIcon>
						<ListItemText sx={{ fontWeight: 'bold' }}>Delete deal</ListItemText>
					</MenuItem>
				</Tooltip>
			</Menu>
			{showDialog && (
				<ConfirmDialog
					openDialog={showDialog}
					handleClose={() => setShowDialog(false)}
					handleConfirm={handleDeleteConfirmation}
					actionName={'delete'}
				/>
			)}
		</>
	);
};

export default DealMenu;
