import { isAfter, isBefore } from 'date-fns';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	clientId: Yup.string().required('Please select a client.'),
	dealName: Yup.string().required('Deal name is required.'),
	policyId: Yup.string().required('Please select a policy'),
	policyTransactionId: Yup.string().required('Please select a policy transaction'),
	policy: Yup.object().nullable(false).required('Please select a policy'),
	claimType: Yup.object().nullable(false).required('Please select a claim type'),
	transaction: Yup.object().nullable(false).required('Please select a policy transaction'),
	files: Yup.array(),
	describeClaim: Yup.string()
		.required('Please provide a description.')
		.max(100, 'Description can\'t be more than 100 characters long.'),
	notes: Yup.string(),
	dateOfLoss: Yup.date()
		.nullable(false)
		.required('Date of loss is required.')
		.test('is-between-dates', 'Date of loss must be within the policy transaction period', function(value) {
			console.log('[validation] parent.transaction', this.parent.transaction);
			if (!this.parent.transaction) {
				return false;
			}
			const { EffectiveDate, FromDate, ToDate } = this.parent.transaction;
			console.log('[validation] dates', 'loss date', value, 'from date', FromDate, 'to date', ToDate);
            
			const normaliseDate = (date) =>
				new Date(date.getFullYear(), date.getMonth(), date.getDate());

			const fromDate = normaliseDate(EffectiveDate ? new Date(EffectiveDate) : new Date(FromDate));
			const toDate = normaliseDate(new Date(ToDate));
			const lossDate = normaliseDate(new Date(value));

			return (isAfter(value, fromDate) || fromDate.getTime() == lossDate.getTime()) && (toDate.getTime() == lossDate.getTime() || isBefore(value, toDate));
		}),
	dateReported: Yup.date().nullable(false).required('Date reported is required.'),
});
