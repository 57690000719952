import React, { useMemo } from 'react';
import { 
	Typography, 
	TableRow, 
	TableCell, 
	Skeleton, 
} from '@mui/material';
import { claimStatusColour, formatDate } from '@/utils/constants';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { useNavigate } from 'react-router-dom';
import { useGetDealPoliciesQuery } from '@/features/deals/dealsApi';
import { BlinkingCircle } from '@/components/icons/BlinkingCircle';
import { paths } from '@/app/routes';

export const ClaimRow = ({ hit: ticket }) => {
	const navigate = useNavigate();

	const policiesQuery = useGetDealPoliciesQuery({ dealId: ticket.id }, { skip: !ticket.id });

	const policyNumber = useMemo(() => policiesQuery?.data?.policies?.[0]?.PolicyNumber ?? '', [policiesQuery?.data?.policies]);
	
	const pipelineQuery = useGetPipelinePropertiesQuery(ticket.pipeline, {
		skip: !ticket.pipeline,
	});
	const stages = pipelineQuery?.data?.stages ?? {};

	const stage = useMemo(() => {
		return ticket.dealStage in stages 
			? stages[ticket.dealStage] 
			: { label: ticket.dealStage, order: 99 };
	}, [stages]);

	const statusColour = claimStatusColour(stages[ticket.dealStage]?.order ?? 0);	

	return (
		<TableRow 
			hover 
			onClick={() => navigate(`${paths.claims}/${ticket.id}`)} 
			style={{ cursor: 'pointer' }}
		>
			<TableCell>
				<Typography color="text.primary" variant="client_claims_table" fontWeight={500}>
					{ticket.dealName}
				</Typography>
			</TableCell>
			<TableCell align="right">
				<Typography variant="client_claims_table">{formatDate(ticket.createDate)}</Typography>
			</TableCell>
			<TableCell align="right">
				<Typography variant="client_claims_table">{formatDate(ticket.dateOfLoss)}</Typography>
			</TableCell>
			<TableCell align="right">
				<Typography variant="client_claims_table">
					{policiesQuery.isFetching ? 
						<Skeleton variant="text" animation="wave" /> 
						: 
						policyNumber}
				</Typography>
			</TableCell>
			<TableCell>
				<Typography color="text.secondary" variant="client_claims_table">
					{pipelineQuery.isFetching ? 
						<Skeleton variant="text" animation="wave" /> 
						: 
						<span>
							<span style={{ paddingRight: '5px' }}>
								<BlinkingCircle fontSize='6px' color={statusColour} />
							</span>
							{stage?.label ?? ''}
						</span>}
				</Typography>
			</TableCell>
		</TableRow>);
};