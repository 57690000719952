import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Box,
	Drawer,
	Button,
	IconButton,
	Collapse,
	Typography,
	Stack,
	Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ClearIcon from '@mui/icons-material/Clear';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TransitionGroup } from 'react-transition-group';
import { RefinementCheckboxList } from '@/components/selects/RefinementCheckboxList';
import { useDispatch, useSelector } from 'react-redux';
import RefinementListChips from '@/components/selects/RefinementListChips';
import { setFilters } from '@/features/table/instantSearchSlice';
import { useTheme } from '@mui/system';
import { useLocation } from 'react-router-dom';

const DRAWER_WIDTH = 400;

const FilterMenu = ({ title, filters: menuFilters, pipeline }) => {
	const dispatch = useDispatch();
	const { pathname: slug } = useLocation();

	const { filters: instantSearchFilters } = useSelector((state) => state.instantSearch);
	const filters = useMemo(() => instantSearchFilters[slug] ?? {}, [instantSearchFilters, slug]);

	const [open, setOpen] = useState(false);

	const [showAll, setShowAll] = useState(false);

	const toggleDrawer = (newOpen) => () => {
		setOpen(newOpen);
	};

	const attributes = menuFilters.map((f) => f.attribute);

	const activeFilters = useMemo(() => Object.entries(filters).reduce((acc, [attribute, values]) => {
		if (attributes.includes(attribute) && (values ?? []).length > 0) {
			acc.push({
				values: filters[attribute],
				...menuFilters.find((f) => f.attribute == attribute),
			});
		}
		return acc;
	}, []), [filters]);

	const transformedFilters = useMemo(() => activeFilters.reduce(
		(acc, { attribute, values, ...props }, index) => {
			values.forEach((value) => {
				acc.push({
					attribute,
					value,
					index,
					...props,
				});
			});
			return acc;
		},
		[]
	), [activeFilters]);

	// const shownFilters = transformedFilters.slice(0, 2);
	// const hiddenFilters = transformedFilters.slice(2);

	const clearFilters = () => {
		attributes.forEach((attribute) => {
			dispatch(setFilters({ slug, type: attribute, values: [] }));
		});
	};

	useEffect(() => {
		if (activeFilters.length === 0) {
			setShowAll(false);
		}
	}, [activeFilters]);

	const currentFilters = useMemo(() => attributes.flatMap((f) => filters[f]).filter((v) => v), [attributes, filters]);

	const DrawerList = (
		<Stack sx={{ width: '100%' }} role='presentation'>
			<Stack direction='row' sx={{ p: '1em' }} spacing={1} alignItems='center'>
				<IconButton onClick={toggleDrawer(false)}>
					<ChevronRightIcon />
				</IconButton>
				<Typography variant='broker_header'>{`${title} Filters`.trim()}</Typography>
			</Stack>
			{activeFilters.length > 0 && (
				<>
					<Grid container spacing={1} px={'1em'}>
						{activeFilters.map(({ attribute, title }, index) => {
							return (
								<RefinementListChips
									key={`chip-${attribute}-${index}`}
									index={index}
									title={title}
									attribute={attribute}
								/>
							);
						})}
					</Grid>
					<Box p={'1em'}>
						<Button variant='text' startIcon={<ClearIcon />} onClick={clearFilters}>
							{`Clear ${currentFilters.length} filters`}
						</Button>
					</Box>
				</>
			)}
			{menuFilters.map((props) => {
				return (
					<RefinementCheckboxList
						key={`refinement-checkbox-list-${props.attribute}`}
						operator='or'
						{...props}
					/>
				);
			})}
		</Stack>
	);

	const HideFiltersButton = (
		<Box display='flex' justifyContent={'flex-end'} px={'0.5em'}>
			<Button
				onClick={() => setShowAll((prev) => !prev)}
				size='small'
				sx={{ minWidth: 'auto' }}
				endIcon={!showAll ? <MoreHorizIcon /> : <ExpandLessIcon />}
			>
				{!showAll ? 'MORE' : 'LESS'}
			</Button>
		</Box>
	);

	return (
		<div>
			<Box display='flex' justifyContent={'flex-end'}>
				{activeFilters.length > 0 && (
					<Tooltip title={`Clear all ${title} filters`}>
						<IconButton size='small' onClick={clearFilters}>
							<ClearIcon />
						</IconButton>
					</Tooltip>
				)}
				<Button variant='text' endIcon={<FilterAltIcon />} onClick={toggleDrawer(true)}>
					{`Filters${currentFilters.length > 0 ? ` (${currentFilters.length})` : ''}`}
				</Button>
			</Box>
			{activeFilters.length > 0 && (
				<Stack direction='row' justifyContent={'flex-end'} width='100%'>
					<Collapse in={showAll} collapsedSize={'2em'}>
						<Grid container spacing={1} px={'1em'} justifyContent={'flex-end'}>
							{activeFilters.map(({ attribute, title }, index) => {
								return (
									<RefinementListChips
										key={`chip-${attribute}-${index}`}
										index={index}
										title={title}
										attribute={attribute}
									/>
								);
							})}
							{currentFilters.length > 1 && showAll && HideFiltersButton}
						</Grid>
					</Collapse>
					<Collapse
						orientation='horizontal'
						in={currentFilters.length > 1 && !showAll}
						sx={{
							width: showAll ? 0 : 'auto',
							flexShrink: 0,
							display: (currentFilters.length > 1 && !showAll) ? 'flex' : 'none',
							overflow: 'hidden',
						}}
					>
						{HideFiltersButton}
					</Collapse>
				</Stack>
			)}
			<Drawer
				open={open}
				onClose={toggleDrawer(false)}
				anchor='right'
				variant='persistent'
				sx={{
					width: DRAWER_WIDTH,
					'& .MuiDrawer-paper': {
						width: DRAWER_WIDTH,
						boxSizing: 'border-box',
						boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
					},
				}}
			>
				{DrawerList}
			</Drawer>
		</div>
	);
};

export default FilterMenu;
