import React, { useMemo } from 'react';
import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { claimStatusColour, firstTwoCapsOrNumbers } from '@/utils/constants';
import { formatDate } from '@/utils/constants';
import { ClientNameAndAvatar } from '@/components/table/ClientNameAndAvatar';
import { BlinkingCircle } from '@/components/icons/BlinkingCircle';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { isAfter } from 'date-fns';
import { useGetOwnerQuery } from '@/features/user/ownerApi';

const today = new Date();

export const TicketRow = ({ hit: ticket }) => {
	const navigate = useNavigate();

	const pipelineQuery = useGetPipelinePropertiesQuery(ticket.pipeline, {
		skip: !ticket.pipeline,
	});
	const stages = pipelineQuery?.data?.stages ?? {};

	const stage = useMemo(() => {
		return ticket.dealStage in stages
			? stages[ticket.dealStage]
			: { label: ticket.dealStage, order: 99 };
	}, [stages]);

	const statusColour = claimStatusColour(stages[ticket.dealStage]?.order ?? 0);

	const due = useMemo(
		() => (ticket?.earliestTask ? new Date(ticket?.earliestTask?.hs_timestamp) : null),
		[ticket?.earliestTask]
	);

	const overdue = useMemo(() => (due ? isAfter(today, due) : false), [due]);

	const ownerQueryParams = { ownerId: ticket.ownerId };
	const { data: owner } = useGetOwnerQuery(ownerQueryParams, {
		skip: !ownerQueryParams.ownerId,
	});

	return (
		<TableRow
			onClick={() => navigate(`${ticket.id}`)}
			hover
			key={`table-row-${ticket.id}`}
			style={{ cursor: 'pointer' }}
		>
			<TableCell width={'15%'}>{ticket.dealName}</TableCell>
			<TableCell width={'30%'}>
				<ClientNameAndAvatar
					title={ticket.clientName}
					subtitle={ticket.contactName}
					initials={firstTwoCapsOrNumbers(ticket?.dealName ?? 'NA')}
					width={300}
				/>
			</TableCell>
			<TableCell width={'10%'}>{ticket.claimType}</TableCell>
			<TableCell width={'10%'}>{ticket.claimNumber ?? ''}</TableCell>
			<TableCell width={'20%'}>{ticket.insurer}</TableCell>
			<TableCell width={'10%'}>
				{`${owner?.firstName ?? '-'} ${owner?.lastName ?? ''}`}
			</TableCell>
			<TableCell width={'10%'}>{formatDate(ticket.dateOfLoss)}</TableCell>
			<TableCell width={'10%'}>
				<Typography sx={{ ...(overdue && { color: 'red', fontWeight: 600 }) }}>
					{due ? formatDate(due) : '-'}
				</Typography>
			</TableCell>
			<TableCell  width={'10%'}>
				{formatDate(ticket.createDate)}
			</TableCell>
			<TableCell width="10%">
				<span>{stage?.label ?? ''}</span>
			</TableCell>
			{/* <TableCell align="right">
				<Typography>{formatDate(ticket.hs_lastmodifieddate, true)}</Typography>
			</TableCell> */}
			{/* <TableCell align="center">
                {ticket.hs_priority === null ? '-' : <PriorityChip priority={ticket.hs_priority} />}
            </TableCell> */}
		</TableRow>
	);
};
