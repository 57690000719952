import React, { useMemo } from 'react';
import { 
	Box, 
	CardHeader,
	Typography,
	CircularProgress,
} from '@mui/material';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { PageHeader } from '@/components/layouts/PageHeader';
import { useSelector } from 'react-redux';
import { DealTableView } from '../DealTableView';
import { KanbanView } from '../KanbanView';
import { getPipelineIcon } from '@/utils/constants';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

export const DealPipelineView = ({pipelineId }) => {
	// const [currentTab, setCurrentTab] = useState('active');

	const isRenewals = pipelineId === process.env.REACT_APP_PIPELINE_RENEWALS;
    
	const { kanbanView } = useSelector((state) => state.userPrefs);
    
	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'renewals' }, { skip: !isRenewals});
	const showNewRenewalsTable = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	// Fetching pipeline properties using a custom hook
	const { 
		data: pipelineData, 
		isLoading, 
		isError, 
		isUninitialized 
	} = useGetPipelinePropertiesQuery(pipelineId, {skip: !pipelineId});
	const pipelineName = pipelineData?.pipelines?.label;

	const loading = isLoading || isUninitialized || (isRenewals && (featureFlagQuery.isLoading || featureFlagQuery.isUninitialized));
    
	return (
		<Box
			sx={{
				backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100'),
				p: 3,
			}}
		>
			{loading
				? 
				<div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress /></div> 
				: 
				isError 
					? 
					<Box>
						<Typography textAlign='center'>
						There was an issue loading this pipeline. Please try again.
						</Typography>
					</Box> 
					: <>
						<CardHeader 
							disableTypography 
							title={<PageHeader title={pipelineName} icon={getPipelineIcon(pipelineId)} showGroupView/>}

						/>
						{kanbanView ? 
							<KanbanView pipelineId={pipelineId} /> : 
							<DealTableView pipelineId={pipelineId} />}
					</>}
		</Box>
	);
};