import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	DialogActions,
	Button,
	Stack,
} from '@mui/material';

const ConfirmEmailDialog = ({
	emailType,
	confirmationOpen,
	setConfirmationOpen,
	email,
	handleSendEmail,
	emailExplanation,
}) => {
	return (
		<Dialog
			open={confirmationOpen}
			onClose={() => setConfirmationOpen(false)}
			maxWidth='xs'
			fullWidth={true}
		>
			<DialogTitle>{`Confirm Send${` ${emailType ?? ''} `}Email`}</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<Typography>
						{`An email will be sent to your email address (${email})${emailExplanation ?? ''}.`}
					</Typography>
					<Typography>
						{
							'We recommend reviewing the email and amending any details prior to sending to your client.'
						}
					</Typography>
				</Stack>
			</DialogContent>
			<DialogActions sx={{ pb: '1.25em' }}>
				<Button onClick={() => setConfirmationOpen(false)} color='primary'>
					Cancel
				</Button>
				<Button
					onClick={() => {
						setConfirmationOpen(false);
						handleSendEmail();
					}}
					color='primary'
					variant='contained'
				>
					{`Send ${emailType} Email`}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmEmailDialog;
