import React, { useMemo } from 'react';
import { 
	TableCell, 
	TableRow, 
} from '@mui/material';
import { ClientNameAndAvatar } from '@/components/table/ClientNameAndAvatar';
import { formatDate } from '@/utils/constants';
import { useNavigate } from 'react-router';
import { paths } from '@/app/routes';

export const ContactRow = ({contact}) => {
	const navigate = useNavigate();

	const getContactInitials = () => {
		if (contact.firstname && contact.lastname) {
			return contact.firstname.charAt(0).toUpperCase() + contact.lastname.charAt(0).toUpperCase();
		} else {
			return 'NA';
		}
	};

	const createdAt = useMemo(() => new Date(contact.createdAt), [contact.createdAt]);

	return (
		<TableRow 
			onClick={() => navigate(`${paths.contacts}/${contact.id}`)}
			hover 
			key={`contact-table-row-${contact.id}`}
			style={{ cursor: 'pointer' }}
		>
			<TableCell width={'25%'}>
				<ClientNameAndAvatar 
					title={`${contact.firstname} ${contact.lastname}`} 
					subtitle={(contact.clientInformation ?? [])[0]?.name ?? ''} 
					initials={getContactInitials()}
				/>
			</TableCell>
			<TableCell width={'25%'}>{contact.email}</TableCell>
			<TableCell width={'25%'} align={'right'}>{contact.phone}</TableCell>
			<TableCell width={'25%'} align={'right'}>{formatDate(createdAt)}</TableCell>
		</TableRow>
	);
};
