import React, { useEffect, useMemo, useState } from 'react';
import {
	Table,
	TableContainer,
	TableFooter,
	TableRow,
	Paper,
	Button,
	TableCell,
	Typography,
	Skeleton,
	Box,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Add } from '@mui/icons-material';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { SearchFolioHead } from '@/components/table/FolioTableHead';
import {
	changeRowsPerPage,
	requestSort,
	changePage,
	setOrderBy,
	setOrder,
} from '@/features/table/clientsTableSlice';
import { InstantSearchFieldAdaptor } from '@/components/SearchField';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/app/routes';

import { InstantSearch, Configure } from 'react-instantsearch';
import { ClientRow } from './ClientRow';
import { HitsTableBody } from '@/components/table/HitsTableBody';
import { HitsTablePagination } from '@/components/table/TablePaginationActions';
import { rowLimits } from '@/components/table/data';
import { searchClient } from '@/utils/typesense';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { ClientTypeRefinementListSelect } from '@/components/selects/ClientTypeRefinementListSelect';

export const clientHeadCells = [
	// { id: 'id', numeric: false, label: null, sortable: false },
	{ id: 'name', numeric: false, label: 'Client name', align: 'left', width: '35%' },
	{ id: 'contactName', numeric: false, label: 'Contact', align: 'left', width: '20%' },
	{ id: 'contactEmail', numeric: false, label: 'Email', align: 'left', width: '20%' },
	{ id: 'contactPhone', numeric: true, label: 'Phone', align: 'right', width: '25%' },
	// { id: 'id', numeric: false, label: '', sortable: false, align: 'center' },
];

export default function ClientTable() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const [prospectFilter, setProspectFilter] = useState('');

	const { groupView, hubspotId: groupViewHubspotId } = useSelector((state) => state.userPrefs);

	const {
		// pageAfter,
		rowsPerPage,
		// searchValue,
		// searchText,
		orderBy,
		order,
		// page,
		// rows,
	} = useSelector((state) => state.clientTable);

	const {
		data: userDetails,
		isLoading,
		isUninitialized,
	} = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);

	const userDetailsHubspotId = userDetails?.hubspotId;
	const hubspotId = groupViewHubspotId ?? userDetailsHubspotId ?? 0;

	const brokerGroupId = userDetails?.brokerId;
	const brokerGroupIds = userDetails?.brokerGroupArray ?? [];

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'prospects' });
	const showProspects = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const headCells = useMemo(() => {
		const cells = clientHeadCells.slice();
		if (showProspects) {
			cells.splice(1, 0, {
				id: 'isProspect',
				numeric: false,
				label: 'Client Type',
				align: 'left',
				sortable: false,
			});
		}
		return cells;
	}, [showProspects]);

	useEffect(() => {
		dispatch(setOrderBy('name'));
		dispatch(setOrder('asc'));
	}, []);

	const handleRequestSort = (event, property) => {
		dispatch(requestSort(property));
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(changeRowsPerPage(event.target.value));
	};

	const sortItems = headCells.reduce((acc, cell, index) => {
		if (index === 0) {
			acc.push({ value: 'clients', label: cell.label });
		}
		acc.push({ value: `clients/sort/${cell.id}:asc`, label: `${cell.label} (asc)` });
		acc.push({ value: `clients/sort/${cell.id}:desc`, label: `${cell.label} (desc)` });
		return acc;
	}, []);

	const filterSchema = useMemo(() => {
		const filters = `${
			groupView ? `brokerGroupId: [${brokerGroupIds.join(',')}]` : `ownerId := ${hubspotId}`
		}${prospectFilter.length > 0 ? ` && ${prospectFilter}` : ''}`;
		const schema = {
			filters,
		};
		console.log('Filter schema', schema);
		return schema;
	}, [brokerGroupIds, groupView, hubspotId, prospectFilter]);

	return (
		<InstantSearch indexName='clients' searchClient={searchClient}>
			<TableContainer component={Paper}>
				{isLoading || isUninitialized ? (
					<Skeleton />
				) : (
					<>
						<Configure {...filterSchema} />
						<Grid container sx={{ alignItems: 'flex-start' }} p={1} px={2} spacing={1}>
							<Grid size={{ xs: 12, sm: 5 }}>
								<InstantSearchFieldAdaptor />
							</Grid>
							<Grid size={{ xs: 12, sm: 'grow' }}></Grid>
							<Grid size={{ xs: 12, sm: 3 }} container justifyContent={'flex-end'}>
								<Button
									component='label'
									variant='contained'
									startIcon={<Add />}
									onClick={() => navigate(paths.createNewBusiness)}
								>
									New Client
								</Button>
							</Grid>
						</Grid>
						{showProspects && (
							<Grid container>
								<Grid size={{ xs: 0, sm: 'grow' }}></Grid>
								{showProspects && (
									<Grid
										size={{ xs: 12, sm: 6 }}
										container
										justifyContent={'flex-end'}
										display={'flex'}
									>
										<Box pr={1}>
											<ClientTypeRefinementListSelect
												attribute='isProspect'
												title='Client Type'
												prospectFilter={prospectFilter}
												setProspectFilter={setProspectFilter}
											/>
										</Box>
									</Grid>
								)}
							</Grid>
						)}

						<Table
							aria-labelledby='tableTitle'
							size='medium'
							aria-label='enhanced table'
							sx={{ minWidth: 700 }}
						>
							<SearchFolioHead
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								headCells={headCells}
								items={sortItems}
							/>
							<HitsTableBody
								TableRowComponent={ClientRow}
								ErrorRowComponent={() => (
									<TableRow>
										<TableCell colSpan={headCells.length} align='center'>
											<Typography variant='subtitle1'>
												There was an error. Please try again.
											</Typography>
										</TableCell>
									</TableRow>
								)}
								EmptyRowComponent={() => (
									<TableRow>
										<TableCell colSpan={headCells.length} align='center'>
											<Typography variant='subtitle1'>
												No clients found
											</Typography>
										</TableCell>
									</TableRow>
								)}
							/>
						</Table>
						<HitsTablePagination
							rowsPerPage={rowsPerPage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							items={rowLimits.map((item) => ({
								label: `${item} rows per page`,
								value: item,
								default: item == rowsPerPage,
							}))}
						/>
					</>
				)}
			</TableContainer>
		</InstantSearch>
	);
}
