import * as Yup from 'yup';
import { CLIENT_ID, DEAL_NAME, FILES } from '../CreateNewBusinessForm/validationSchema';

export const validationSchema = Yup.object().shape({
	[CLIENT_ID]: Yup.string().required('Client is required.'),
	[DEAL_NAME]: Yup.string().required('Deal name is required.'),
	[FILES]: Yup.array(),
	notes: Yup.string(),
	priority: Yup.string().nullable(true),
	policies: Yup.array()
		// .min(1, 'At least one policy is required')
		.nullable(true),
	policyId: Yup.number()
		// .min(0, 'Please select a policy 000')
		.nullable(true),
	// policyTransactionId: Yup.number().nullable(false).required('Please select a policy Transaction'),
	// policyId: Yup.number().required('Please select a policy').min(0, 'Please select a policy'),
}).test(
	'either-policyId-or-policies',
	'Please select a policy.',
	function (value) {
		const { policyId, policies } = value;
		return (policyId != null && policyId >= 0) || (policies && policies.length > 0);
	}
);