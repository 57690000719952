import React from 'react';
import { Box, Typography,  } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ClientDetailField } from '@/components/ClientDetailEntry/clientDetailEntry';
import {
	addressSubtitles,
	clientHeaders,
	contactHeaders,
} from '@/components/ClientDetailEntry/clientDetailData';
import { ClientCategory } from '@/components/cards/DealCardValues/ClientCategory';

export const DetailsTab = ({ displayContactDetails = true }) => {
	const contactSection = () => {
		return (
			<Box style={{ marginBottom: '18px', padding: '12px' }}>
				<Typography variant='details_header'>Contact Details</Typography>
				{Object.entries(contactHeaders).map(([key, header]) => {
					return (
						<Grid container padding={'1em 0 0.5em'} key={`detail-entry-${key}`}>
							<Grid size={12}>
								{key === 'fullname' ? (
									<Grid container columns={2} spacing={1}>
										<Grid size={1}>
											<ClientDetailField
												type={'contact'}
												title={header}
												header={'firstname'}
											/>
										</Grid>
										<Grid size={1}>
											<ClientDetailField
												type={'contact'}
												title={header}
												header={'lastname'}
											/>
										</Grid>
									</Grid>
								) : (
									<ClientDetailField title={header} type={'contact'} header={key} readOnly={key === 'email'} />
								)}
							</Grid>
						</Grid>
					);
				})}
			</Box>
		);
	};

	const clientSection = () => {
		return (
			<Box style={{ marginBottom: '18px', padding: displayContactDetails ? '12px' : '0' }}>
				{displayContactDetails && (
					<Typography variant='details_header'>Client Details</Typography>
				)}
				<ClientCategory/>
				{Object.entries(clientHeaders).map(([key, header]) => {
					return (
						<Grid container padding={'1em 0 0.5em'} key={`detail-entry-${key}`}>
							<Grid size={12}>
								{key === 'address' ? (
									<Grid container columns={{ xs: 1, sm: 2 }} spacing={1}>
										{Object.entries(addressSubtitles).map(([k, h]) => (
											<Grid size={1} key={`detail-entry-address-${k}`}>
												<ClientDetailField
													type={'client'}
													header={k}
													title={h}
													subtitle={h}
												/>
											</Grid>
											
										))}
									</Grid>
								) : (
									<ClientDetailField
										type={'client'}
										header={key}
										title={header}
										readOnly={['name', 'email'].includes(key)}
									/>
								)}
							</Grid>
						</Grid>
					);
				})}
			</Box>
		);
	};

	return (
		<Box>
			{displayContactDetails && contactSection()}
			{clientSection()}
		</Box>
	);
};
