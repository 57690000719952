import React, { useEffect } from 'react';
import { Checkbox } from '@mui/material';
import { FULL_NEEDS_ANALYSIS } from '@/components/forms/CreateNewBusinessForm/validationSchema';


export const ClientCheckbox = ({ valuesArray, value, setValues, disabled, limitSelect = false, ...props }) => {
	
	const handleChange = () => {
		let newValues;
		if (valuesArray.includes(value)) {
			newValues = valuesArray.filter((i) => i !== value);
		} else {
			if (value === FULL_NEEDS_ANALYSIS) {
				newValues = [FULL_NEEDS_ANALYSIS];
			} else if (valuesArray.includes(FULL_NEEDS_ANALYSIS)) {
				newValues = [];
			} else {
				if (limitSelect && valuesArray.length === 2) {
					newValues = [valuesArray[1], value];
				} else {
					newValues = [...valuesArray, value];
				}
			}
		}

		setValues(newValues);
	};

	// const isFullNeedsAnalysisSelected = valuesArray.includes(FULL_NEEDS_ANALYSIS);
	const isDisabled = disabled;// || (isFullNeedsAnalysisSelected && value !== FULL_NEEDS_ANALYSIS);

	return (
		<Checkbox
			checked={valuesArray.includes(value)}
			onChange={handleChange}
			disabled={isDisabled}
			{...props}
		/>
	);
};
