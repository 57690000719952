import { createSlice, current } from '@reduxjs/toolkit';

const loadFilters = () => {
	const storageValue = localStorage.getItem('instantSearch');
	const parsedJson = storageValue ? JSON.parse(storageValue) : { filters: {} };
	// const filters = {
	// 	'/renewals': {
	// 		'status': ['Not Started'] // automatically add filter for status on load
	// 	},
	// 	...parsedJson.filters ?? {},
	// };
	return parsedJson.filters ?? {};
};

export const instantSearchSlice = createSlice({
	name: 'instantSearch',
	initialState: {
		filters: loadFilters(),
	},
	reducers: {
		setFilters: (state, action) => {
			const { slug, type, values } = action.payload;
			if (values && typeof values == 'object' && Array.isArray(values)) {
				const valuesArray = values.filter(i => i);
				if (!state.filters[slug]) {
					state.filters[slug] = {};
				}
				state.filters[slug][type] = valuesArray;
    
				localStorage.setItem('instantSearch', JSON.stringify({ filters: state.filters }));
			}
		},
	},
});

export const { setFilters } = instantSearchSlice.actions;

export default instantSearchSlice.reducer;