import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress, InputAdornment, TextField, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useGetDealPolicyTransactionsQuery } from '@/features/deals/dealsApi';
import { formatDateDayToYear } from '@/utils/constants';

export const PolicyTransactionSelect = ({
	policyId,
	policyTransaction,
	setPolicyTrans,
	disabled,
}) => {
	const [open, setOpen] = useState(false);

	const {
		data: policyTransData,
		isLoading,
		isFetching,
	} = useGetDealPolicyTransactionsQuery({ policyId: policyId }, { skip: !policyId });

	const policyOptions = useMemo(() => policyTransData ?? [], [policyTransData]);

	const getFormattedString = (policyTrans) => {
		console.log('policy trans data: ', policyTrans);
		
		if (Object.keys(policyTrans ?? {}).length > 0) {
			return `INV ${policyTrans?.InvoiceNumber} - ${formatDateDayToYear(policyTrans?.FromDate ?? policyTrans?.PolicyFromDate)} to ${formatDateDayToYear(
				policyTrans?.ToDate ?? policyTrans?.PolicyToDate
			)} (Effective ${formatDateDayToYear(policyTrans?.EffectiveDate ?? policyTrans?.PolicyEffectiveDate)}), ${
				policyTrans?.TransactionTypeName ?? policyTrans?.TransactionType
			}, ${policyTrans?.PolicyNumber} - ${policyTrans?.InsurerName}`;
		}
		return '';
	};

	return (
		<Autocomplete
			id='policy-select'
			loading={isLoading || isFetching}
			loadingText='Loading policy transactions...'
			options={policyOptions}
			getOptionLabel={(policyTrans) => getFormattedString(policyTrans)}
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			renderOption={(props, policyTrans) => (
				<Box component='li' {...props}>
					{getFormattedString(policyTrans)}
				</Box>
			)}
			value={Object.keys(policyTransaction ?? {}).length > 0 ? policyTransaction : null}
			renderInput={(params) => (
				<TextField
					{...params}
					placeholder='Search for policy'
					// sx={{ opacity: !policyTransData ? '0.5' : '1' }}
					InputProps={{
						...params.InputProps,
						...((isLoading || isFetching) && {
							startAdornment: (
								<InputAdornment position='start'>
									<CircularProgress color='inherit' size={20} />
								</InputAdornment>
							),
						}),
					}}
				/>
			)}
			isOptionEqualToValue={(option, value) => option.Id === value.Id}
			onChange={(_, value) => {
				setPolicyTrans(value);
			}}
			disabled={!policyId || disabled}
		/>
	);
};
