import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { AttachmentsTable } from '@/components/tables/AttachmentsTable';
import { determineContext } from '@/hooks/determineContext';
import { AttachmentsContextProvider } from '@/context/attachmentsContext';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumbs, setItemId } from '@/features/table/attachmentsTableSlice';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

export const AttachmentsTab = () => {
	const dispatch = useDispatch();

	const { deal, client, dealRow } = determineContext();
	const rowId = useMemo(() => deal ? dealRow?.driveId : client?.clientRow?.driveId, [deal, dealRow?.driveId, client?.clientRow?.driveId]);

	const { itemId, breadcrumbs } = useSelector((state) => state.attachmentsTable);

	// useEffect(() => {
	// 	const name = deal ? dealRow?.dealName : client?.clientRow?.name;
	// 	if (itemId != rowId) {
	// 		dispatch(setItemId(rowId));
	// 		dispatch(setBreadcrumbs({ id: rowId, name }));
	// 	}
	// }, [rowId, deal, dealRow?.dealName, client?.clientRow?.name]);

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'folderView' });
	const showNewFoldersFeature = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	return (
		<AttachmentsContextProvider deal={deal} client={client}>
			<AttachmentsTable canDownload={true} />
		</AttachmentsContextProvider>
	);
};
