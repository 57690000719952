/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	TextField,
	InputAdornment,
	IconButton,
	CircularProgress,
	Box,
	Chip,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { InstantSearch, Configure, useHits, useSearchBox } from 'react-instantsearch';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { searchClient } from '@/utils/typesense';
import CheckIcon from '@mui/icons-material/Check';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

const AutocompleteContactsInput = ({
	query,
	refine,
	clear,
	hits,
	isSearching,
	setClient,
	clientId,
	disabled,
	attendees,
}) => {
	const [open, setOpen] = useState(false);
	const [selectedContacts, setSelectedContacts] = useState([]);

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const contactHits = useMemo(() => {
		const filteredHits = hits.reduce((acc, hit) => {
			acc[`${hit.contactEmail.toString().trim()}-${hit.contactName.toString().trim()}`] = hit;
			return acc;
		}, {});
		return Object.values(filteredHits);
	}, [hits]);

	const handleSearch = () => {
		if (query.length > 0) {
			refine();
			if (isValidEmail(query)) {
				const newContact = {
					id: query,
					contactName: query,
					contactEmail: query,
				};
				const existingContactEmail = selectedContacts.map(
					(contact) => contact.contactEmail
				);
				if (!existingContactEmail.includes(newContact.contactEmail)) {
					const updatedContacts = [...selectedContacts, newContact];
					setSelectedContacts(updatedContacts);
					setClient(updatedContacts);
				}
			}
		}
	};

	useEffect(() => {
		if (Array.isArray(attendees) && attendees.length > 0) {
			const initialContacts = attendees.map((attendee) => ({
				id: attendee.emailAddress,
				contactName: attendee.contactName ?? attendee.name ?? attendee.emailAddress,
				contactEmail: attendee.emailAddress,
			}));
			if (selectedContacts.length === 0) {
				setSelectedContacts(initialContacts);
			}
		}
	}, [attendees]);

	const handleSelectedContactDelete = (index) => {
		const updatedContacts = [...selectedContacts];
		updatedContacts.splice(index, 1);
		setSelectedContacts(updatedContacts);
		setClient(updatedContacts);
	};

	const handleReset = () => {
		setClient(null);
		setOpen(false);
		clear();
		// setSelectedContacts([]);
	};

	const handleAttendeeSelect = (newSelectedContacts) => {
		const existingEmails = selectedContacts.map((contact) => contact.contactEmail);
		const updatedContacts = [...selectedContacts];

		newSelectedContacts.forEach((contact) => {
			if (!existingEmails.includes(contact.contactEmail)) {
				updatedContacts.push(contact);
			}
		});

		setSelectedContacts(updatedContacts);
		setClient(updatedContacts);
	};

	useEffect(() => console.log('HITS', hits), [hits]);

	return (
		<>
			{(selectedContacts ?? []).length > 0 && (
				<Box mt={1}>
					{selectedContacts.map((contact, index) => (
						<Tooltip
							title={contact.contactEmail}
							placement={'top'}
							key={`chip-${index}-${contact.contactEmail}`}
						>
							<Chip
								label={contact.contactName}
								onDelete={() => handleSelectedContactDelete(index)}
								variant='contained'
								style={{ margin: '4px' }}
							/>
						</Tooltip>
					))}
				</Box>
			)}
			<Autocomplete
				id='client-select'
				multiple={true}
				loading={isSearching}
				loadingText={'Loading contacts...'}
				options={query?.length > 0 ? contactHits : []}
				open={open}
				disabled={disabled}
				noOptionsText={query?.length > 0 ? 'No contacts found' : 'Type to search'}
				filterOptions={(x) => x}
				renderOption={(props, option) => (
					<Stack
						direction='row'
						alignItems={'center'}
						justifyContent={'space-between'}
						{...props}
						key={`client-select-${option.id}`}
						width={'100%'}
					>
						<Stack
							direction='row'
							alignItems={'center'}
							justifyContent={'space-between'}
							width={'100%'}
						>
							<Typography>{option.contactName}</Typography>
							<Typography variant='task_updated'>{option.contactEmail}</Typography>
						</Stack>
						{selectedContacts.some((c) => c.contactEmail == option.contactEmail) && (
							<CheckIcon sx={{ fontSize: '18px', ml: '0.5em' }} />
						)}
					</Stack>
				)}
				inputValue={query}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				getOptionLabel={(option) => option.contactName}
				onInputChange={(_, value, reason) => {
					console.log('REASON', reason);
					if (reason !== 'reset') {
						refine(value);
					}
				}}
				onKeyDown={(e) => {
					if (e.key === 'Enter' || (e.key === ',' && isValidEmail(query))) {
						e.preventDefault();
						if (isValidEmail(query)) {
							e.defaultMuiPrevented = true;
							handleSearch();
						}
					}
				}}
				renderInput={(params) => (
					<TextField
						placeholder={'Search for existing contacts or enter emails'}
						{...params}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<InputAdornment position='start'>
									<IconButton
										disabled={isSearching || disabled}
										sx={{ padding: '0' }}
										onClick={handleSearch}
									>
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position='end'>
									{isSearching ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{query.length > 0 && (
										<IconButton
											size='small'
											disabled={isSearching || disabled}
											sx={{ padding: '0' }}
											onClick={handleReset}
										>
											<ClearIcon fontSize='20px' />
										</IconButton>
									)}
								</InputAdornment>
							),
						}}
					/>
				)}
				isOptionEqualToValue={(option, value) =>
					option.contactEmail === value.contactEmail &&
					option.contactName === value.contactName
				}
				onChange={(_, value) => handleAttendeeSelect(value)}
			/>
		</>
	);
};

const AutocompleteInput = ({
	query,
	refine,
	clear,
	hits,
	isSearching,
	setClient,
	clientId,
	disabled,
	clientName,
}) => {
	const [open, setOpen] = useState(false);

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'prospects' });
	const showProspects = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const options = useMemo(() => (query.length > 0 ? hits : []), [query, hits]);

	useEffect(() => {
		refine(clientName);
	}, [clientName]);

	const handleSearch = () => {
		if (query.length > 0) {
			refine();
		}
	};

	const handleReset = () => {
		setClient(null);
		setOpen(false);
		clear();
		// setSelectedContacts([]);
	};

	return (
		<>
			<Autocomplete
				id='client-select'
				loading={isSearching}
				loadingText={'Loading clients...'}
				options={options}
				open={open}
				disabled={Boolean(disabled)}
				noOptionsText={query.length > 0 ? 'No clients found' : 'Type to search'}
				// value={value}
				filterOptions={(x) => x}
				renderOption={(props, option) => (
					<Box
						display={'flex'}
						sx={{ alignItems: 'center', justifyContent: 'space-between' }}
						{...props}
						key={`client-select-${option.id}`}
					>
						<Box flexGrow={1} textAlign='left'>
							<Stack direction='row'>
								{option.name}
								{option.isProspect && showProspects && (
									<Chip
										size='small'
										label='PROSPECT'
										variant='outlined'
										sx={{ color: 'darkgray', ml: '1em' }}
									/>
								)}
							</Stack>
						</Box>
						{option.objectID === clientId && <CheckIcon sx={{ fontSize: '18px' }} />}
					</Box>
				)}
				inputValue={query}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				getOptionLabel={(option) => option.name}
				onInputChange={(_, value, reason) => {
					if (reason !== 'reset') {
						refine(value);
					}
				}}
				renderInput={(params) => (
					<TextField
						placeholder={
							clientId
								? hits.find((hit) => hit.id === clientId)?.name ||
								  ((clientName ?? '').length > 0 ? clientName : 'Search for client')
								: 'Search for client'
						}
						{...params}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
								handleSearch();
							}
						}}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<InputAdornment position='start'>
									<IconButton
										disabled={Boolean(isSearching || disabled)}
										sx={{ padding: '0' }}
										onClick={handleSearch}
									>
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position='end'>
									{isSearching ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{query.length > 0 && (
										<IconButton
											size='small'
											disabled={Boolean(isSearching || disabled)}
											sx={{ padding: '0' }}
											onClick={handleReset}
										>
											<ClearIcon fontSize='20px' />
										</IconButton>
									)}
								</InputAdornment>
							),
						}}
					/>
				)}
				isOptionEqualToValue={(option, value) => {
					return option.id === value.id;
				}}
				onChange={(_, value) => {
					setClient(value);
					refine(value?.name ?? '');
				}}
			/>
		</>
	);
};

export const InstantClientSelect = ({
	clientId,
	clientName,
	setClient,
	disabled,
	showContact,
	filterProspects,
	attendees = [],
}) => {
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const userQuery = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
	const brokerGroupId = userQuery.data?.brokerId;
	const brokerGroupIds = userQuery.data?.brokerGroupArray ?? [];

	const clientNameFilter = clientName ? `name: "${clientName}"` : '';
	const filterSchema = useMemo(() => {
		const schema = {
			filters: `brokerGroupId: [${brokerGroupIds.join(',')}]${
				clientNameFilter ? ` && ${clientNameFilter}` : ''
			}${filterProspects ? ' && isProspect:!=true' : ''}`,
		};
		console.log('Filter schema', schema);
		return schema;
	}, [brokerGroupIds, clientName]);

	return (
		<InstantSearch searchClient={searchClient} indexName='clients'>
			<Configure hitsPerPage={15} {...filterSchema} />
			<InstantClientSelectWrapper
				setClient={setClient}
				clientId={clientId}
				disabled={Boolean(disabled || userQuery.isLoading)}
				clientName={clientName}
				showContact={showContact}
				attendees={attendees}
			/>
		</InstantSearch>
	);
};

const InstantClientSelectWrapper = ({
	setClient,
	clientId,
	disabled,
	clientName,
	showContact,
	attendees,
	...props
}) => {
	const { items } = useHits(props);

	const updatedHits = useMemo(
		() => items.map((hit) => ({ ...hit, id: hit.hubspotId ?? hit.id, docId: hit.id })),
		[items]
	);

	const searchBoxApi = useSearchBox(props);

	const params = {
		hits: updatedHits,
		setClient,
		clientId,
		disabled,
		clientName,
	};

	return showContact ? (
		<AutocompleteContactsInput {...params} {...searchBoxApi} attendees={attendees} />
	) : (
		<AutocompleteInput {...params} {...searchBoxApi} />
	);
};
