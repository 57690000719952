/* eslint-disable no-mixed-spaces-and-tabs */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useGetDealRowQuery } from '@/features/deals/dealsApi';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { useDispatch, useSelector } from 'react-redux';
import { useMsGraph } from '@/hooks/useMsGraph';
import { useSharepoint } from '@/hooks/useSharepoint';
import { setBreadcrumbs, setItemId } from '@/features/table/attachmentsTableSlice';
import { folderStructure } from '@/utils/constants';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

const initialState = {
	deal: {},
	client: {},
	sharepoint: {},
	isLoading: true,
	isError: false,
	dealRow: {},
	files: [],
};

const AttachmentsContext = createContext(initialState);

export const useAttachmentsContext = () => {
	const context = useContext(AttachmentsContext);
	if (!context) {
		throw new Error('useAttachmentsContext must be used within an AttachmentsContextProvider');
	}
	return context;
};

export const AttachmentsContextProvider = ({ deal, client, children }) => {
	const ownerId = deal?.hubspot_owner_id ?? client?.hubspot_owner_id;
	const userQuery = useGetUserDetailsQuery({ hubspotId: ownerId }, { skip: !ownerId });
	const dispatch = useDispatch();

	const clientRow = client?.clientRow;
	const [loading, setLoading] = useState(true);
	const [files, setFiles] = useState([]);

	const dealRowQuery = useGetDealRowQuery(deal?.hs_object_id ?? deal?.id, {
		skip: !deal?.hs_object_id && !deal?.id,
	});

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'folderView' });
	const showNewFoldersFeature = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const { refreshIndicator, itemId, breadcrumbs, searchValue } = useSelector(
		(state) => state.attachmentsTable
	);

	const { getClientGraphFolders, getClientGraphFolderByItemId, searchClientGraphFolders } =
		useClientStorageHooks(userQuery.data);

	const isLoading =
		userQuery.isLoading ||
		userQuery.isUninitialized ||
		(deal && dealRowQuery.isLoading) || featureFlagQuery.isLoading || featureFlagQuery.isUninitialized || 
		loading;
	const isError = userQuery.isError || !ownerId;

	const sharepoint = useMemo(() => {
		const isValidSharepoint =
			userQuery.data?.sharepointSite != null &&
			userQuery.data?.clientSiteSuffix != null &&
			userQuery.data?.clientFolder != null &&
			userQuery.data?.clientSite != null;
		return {
			sharepointSite: userQuery.data?.sharepointSite,
			clientSiteSuffix: userQuery.data?.clientSiteSuffix,
			clientFolder: userQuery.data?.clientFolder,
			clientSite: userQuery.data?.clientSite,
			isValid: isValidSharepoint,
		};
	}, [userQuery.data]);

	const getFolderPaths = (folders) => {
		const folderMap = folders.reduce((acc, folder) => {
			acc[folder.id] = folder;
			return acc;
		}, {});

		const buildPath = (folder) => {
			const { name, parentReference } = folder;

			if (!parentReference || !parentReference.id) {
				return name;
			}

			const parentFolder = folderMap[parentReference.id];
			if (parentFolder) {
				return `${buildPath(parentFolder)}/${name}`;
			}

			return name;
		};

		return folders.map((folder) => buildPath(folder));
	};

	useEffect(() => {
		const rowId = deal ? dealRowQuery.data?.driveId : clientRow?.driveId;
		dispatch(setItemId(rowId));
	}, [clientRow?.driveId, dealRowQuery.data?.driveId]);

	useEffect(() => {
		const updateMsGraph = async () => {
			// const { files, folders } = await getFoldersFunction(userQuery.data?.clientFolderDriveId, itemId, searchValue);
			const { files, folders } =
				showNewFoldersFeature == false
					? await getClientGraphFolders(clientRow)
					: searchValue.length > 0
						? await searchClientGraphFolders(
							userQuery.data?.clientFolderDriveId,
							itemId,
							searchValue
					  )
						: await getClientGraphFolderByItemId(
							userQuery.data?.clientFolderDriveId,
							itemId
					  );
            
			const filesAndFolders = showNewFoldersFeature ? files.concat(folders) : [...files];
			const folderPaths = getFolderPaths(folders);

			const nonDeletableFolderNames = Object.values(folderStructure).map((folder) =>
				decodeURI(folder.normalize())
			);

			const nextBreadcrumb = filesAndFolders?.[0]?.parentReference;
			// console.log('NEXT breadcrumb', nextBreadcrumb, filesAndFolders, breadcrumbs);
			if (nextBreadcrumb && !breadcrumbs.some((b) => b.id == nextBreadcrumb?.id)) {
				dispatch(setBreadcrumbs(nextBreadcrumb));
			}

			setFiles(
				filesAndFolders.map((f) => {
					const suffix = f.name.split('.');
					const name = (f?.name ?? '').normalize();
					let cannotDelete = false;
					try {
						cannotDelete =
							!Object.keys(f).includes('file') &&
							!nonDeletableFolderNames.includes(decodeURI(name));
					} catch (err) {
						console.log(`Error decoding ${f.name}`, err);
						cannotDelete =
							!Object.keys(f).includes('file') &&
							!nonDeletableFolderNames.includes(name);
					}
					return {
						...f,
						type: Object.keys(f).includes('file')
							? suffix[suffix.length - 1]
							: '000folder',
						Description: '',
						directory: folderPaths.find((p) => p.endsWith(f.parentReference.name)),
						createdDateTimeUnix: Date.parse(f.createdDateTime),
						lastModifiedTimeUnix: Date.parse(f.lastModifiedDateTime),
						cannotDelete,
						isFolder: !Object.keys(f).includes('file'),
					};
				})
			);
			setLoading(false);
		};
		console.log('REFRESH INDICATOR', refreshIndicator, 'itemId', itemId);
		if (
			userQuery.data &&
			itemId &&
            (!(featureFlagQuery.isLoading || featureFlagQuery.isFetching || featureFlagQuery.isUninitialized)) && 
			featureFlagQuery.isSuccess && (showNewFoldersFeature === true || showNewFoldersFeature === false)
		) {
			setLoading(true);
			updateMsGraph();
		} else {
			if (files.length !== 0) {
				setFiles([]);
			}
		}
	}, [
		userQuery.data,
		itemId,
		refreshIndicator,
		searchValue,
		clientRow,
		showNewFoldersFeature,
		featureFlagQuery.isSuccess,
		featureFlagQuery.isLoading,
		featureFlagQuery.isFetching,
		featureFlagQuery.isUninitialized
	]);

	const contextValue = {
		deal,
		client,
		dealRow: dealRowQuery.data ?? {},
		files,
		isLoading,
		isError,
		sharepoint,
	};

	useEffect(() => {
		console.log('📋 ~ ATTACHMENTS CONTEXT VALUE', contextValue);
	}, [contextValue]);

	return (
		// Provide the context variables to the children components.
		<AttachmentsContext.Provider value={contextValue}>{children}</AttachmentsContext.Provider>
	);
};

export default AttachmentsContext;
