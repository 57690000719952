/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import {
	Typography,
	Accordion,
	AccordionSummary,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ExpandMore } from '@mui/icons-material';
import { folioBlue } from '@/utils/constants';
import { RiskClasses } from '@/components/cards/DealCardValues/RiskClasses';
import { NeedsAnalysisDropdownContainer } from '@/components/cards/DealCardValues/NeedsAnalysis';
import { AdditionalProducts } from '@/components/cards/DealCardValues/AdditionalProducts';

export const NeedsAnalysisAccordian = () => {
	//LABELS FOR SCOPE HAVE BEEN CHANGED NEED TO ADJUST WHEN COMES TO DEAL VIEW
	const [expanded, setExpanded] = useState(true);

	return (
		<Accordion
			elevation={0}
			disableGutters
			square
			expanded={expanded}
			onChange={() => setExpanded(prev => !prev)}
			sx={{
				backgroundColor: 'transparent',
				'&:not(:last-child)': {
					borderBottom: 0,
				},
				'&::before': {
					display: 'none',
				},
				width: '100%'
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMore sx={{ color: folioBlue }} />}
				sx={{
					borderBottom: expanded ? '1px solid #d3d3d3' : 'none',
					padding: 0,
					marginBottom: '1em'
				}}
			>
				<Typography variant='form_label'>Needs Analysis</Typography>
			</AccordionSummary>
			<Grid container spacing={3}>
				<Grid size={12}>
					<RiskClasses />
				</Grid>
				<Grid size={12}>
					<NeedsAnalysisDropdownContainer />
				</Grid>
				<Grid size={12}>
					<AdditionalProducts />
				</Grid>
			</Grid>
		</Accordion>
	);
};
