/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo } from 'react';
import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { formatDate, newBusinessStatusColour } from '@/utils/constants';
import { ClientNameAndAvatar } from '@/components/table/ClientNameAndAvatar';
import { firstTwoCapsOrNumbers } from '@/utils/constants';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { BlinkingCircle } from '@/components/icons/BlinkingCircle';

export const DealRow = ({ hit: deal }) => {
	const navigate = useNavigate();

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'prospects' });
	const showProspects = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const isNewBusiness = deal.pipeline == process.env.REACT_APP_PIPELINE_NEW_BUSINESS;

	const pipelineQuery = useGetPipelinePropertiesQuery(deal.pipeline, {
		skip: !deal.pipeline,
	});
	const stages = pipelineQuery?.data?.stages ?? {};

	const ownerQueryParams = { ownerId: deal.ownerId };
	const { data: owner } = useGetOwnerQuery(ownerQueryParams, {
		skip: !ownerQueryParams.ownerId,
	});

	const stage = useMemo(() => {
		return deal.dealStage in stages
			? stages[deal.dealStage]
			: { label: deal.dealStage, order: 99 };
	}, [stages]);
	
	const statusColour = newBusinessStatusColour(stages[deal.dealStage]?.order ?? 0);

	return (
		<TableRow
			onClick={() => navigate(`${deal.id}`)}
			hover
			key={`table-row-${deal.id}`}
			style={{ cursor: 'pointer' }}
		>
			{/* <TableCell>
        <Checkbox />
    </TableCell> */}
	
			 <TableCell>
				<Typography color='text.primary'>{deal.dealName ?? 'Deal not found'}</Typography>
			</TableCell> 
			<TableCell>
				<ClientNameAndAvatar
					title={deal.clientName ?? 'Client not found'}
					subtitle={deal.contactName ?? 'Unknown'}
					initials={firstTwoCapsOrNumbers(deal?.description ?? deal.dealName ?? 'NA')}
					width={300}
				/>
			</TableCell>
			{/* <TableCell>
				<ClientNameAndAvatar
					title={deal.clientName ?? 'Client not found'}
					subtitle={deal.description ?? deal.dealName}
					initials={firstTwoCapsOrNumbers(deal?.description ?? deal.dealName ?? 'NA')}
					width={300}
				/>
			</TableCell> */}
			{/* <TableCell>
				<Typography color='text.primary'>{deal.contactName ?? 'Unknown'}</Typography>
			</TableCell> */}
			{showProspects && isNewBusiness && (
				<TableCell>
					<Typography color='text.primary'>
						{deal?.isProspect ? 'Prospect' : 'Client'}
					</Typography>
				</TableCell>
			)}
			<TableCell>
				<Typography color='text.primary'>{`${owner?.firstName ?? '-'} ${
					owner?.lastName ?? ''
				}`}</Typography>
			</TableCell>
			<TableCell align='right'>
				<Typography>{formatDate(deal.createDate)}</Typography>
			</TableCell>
			<TableCell align='right'>
				<Typography>{formatDate(deal.renewalDate)}</Typography>
			</TableCell>
			<TableCell width='300'>
				<span>{stage?.label ?? ''}</span>
			</TableCell>
			{/* <TableCell align="center">
				{ deal.hs_priority === null ? '-' : <PriorityChip priority={deal.hs_priority}  /> }
			</TableCell> */}
			{/* <TableCell align="center">
			<Button onClick={() => navigate(`${deal.id}`)} variant="table_action" >Open</Button>
		</TableCell> */}
		</TableRow>
	);
};
