/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import {
	Typography,
	Box,
	IconButton,
	Skeleton,
	Stack,
	Tooltip,
	Breadcrumbs,
	Link,
	FormHelperText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSharepoint } from '@/hooks/useSharepoint';
import {
	setRows,
	setLoading,
	triggerRefresh,
	setSearchValue,
	setItemId,
	setBreadcrumbs,
	resetSearchFields,
	setSearchText,
} from '@/features/table/attachmentsTableSlice';
import {
	DataGrid,
	GridToolbar,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import DataGridMultilinePopper from '@/components/table/DataGridMultilinePopper';
import { folioBlue, formatDate, specialChars, truncateFilename } from '@/utils/constants';
import { showToast } from '@/features/toast/toastSlice';
import { useAttachmentsContext } from '@/context/attachmentsContext';
import SearchField from '@/components/SearchField';
import PreviewIcon from '@mui/icons-material/Preview';
import FolderIcon from '@mui/icons-material/Folder';
import { Warning } from '@mui/icons-material';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { useMsGraph } from '@/hooks/useMsGraph';
import { getComparator, stableSort } from '@/components/table/functions';
import { FolderBreadcrumbs } from './FolderBreadcrumbs';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

const NoRowsOverlay = () => {
	return (
		<Box alignItems='center' width='100%' height='100%' pl={'0.5em'} pt='0.5em'>
			<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>No attachments found.</Typography>
		</Box>
	);
};

const NoRowsErrorOverlay = () => {
	return (
		<Box alignItems='center' width='100%' height='100%' pl={'0.5em'} pt='0.5em'>
			<Typography sx={{ color: 'red' }}>Error fetching attachments.</Typography>
		</Box>
	);
};

const AttachmentsToolbar = () => {
	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
		</GridToolbarContainer>
	);
};

export const AttachmentsTable = ({ canDownload }) => {
	const dispatch = useDispatch();

	const { deal, client, files, sharepoint, isLoading, isError, dealRow } =
		useAttachmentsContext();

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'folderView' });
	const showNewFoldersFeature = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const [sortModel, setSortModel] = useState([{ field: 'createdDateTimeUnix', sort: 'desc' }]);

	useEffect(() => {
		if (showNewFoldersFeature) {
			setSortModel([{ field: 'type', sort: 'asc' }]);
		}
	}, [showNewFoldersFeature]);

	const { getListItem } = useMsGraph();

	const handleReset = () => {
		dispatch(resetSearchFields());
	};

	const setActiveFolder = (row) => {
		handleReset();
		dispatch(setItemId(row.id));
		dispatch(
			setBreadcrumbs({
				id: row.id,
				name: row.name,
			})
		);
	};

	const {
		// orderBy,
		// order,
		// page,
		rows,
		itemId,
		loading,
		error,
		breadcrumbs,
		// refreshIndicator,
		searchValue,
		searchText,
	} = useSelector((state) => state.attachmentsTable);

	const allLoading = loading || isLoading;
	const allError = error || isError;

	const columns = [
		// ...(canDownload != null && canDownload != false
		// 	? [
		// 		{
		// 			field: 'webUrl',
		// 			headerName: '',
		// 			sortable: false,
		// 			width: GRID_CHECKBOX_SELECTION_COL_DEF.width,
		// 			resizable: false,
		// 			renderCell: ({ row }) => (
		// 				<IconButton
		// 					color='primary'
		// 					href={`${row.webUrl}?web=1`}
		// 					target='_blank'
		// 					rel='noopener noreferrer'
		// 					sx={{ color: folioBlue }}
		// 				>
		// 					<PreviewIcon />
		// 				</IconButton>
		// 			),
		// 		},
		// 	  ]
		// 	: []),
		{
			field: 'name',
			numeric: false,
			headerName: 'Document name',
			editable: true,
			hideable: false,
			align: 'left',
			flex: 2,
			minWidth: 200,
			display: 'flex',
			renderHeader: (params) => <strong>DOCUMENT NAME</strong>,
			renderCell: ({ row }) => {
				const name = truncateFilename(row?.name ?? '', 50);
				return (
					<Tooltip
						title={row.isFolder ? '' : 'Double-click to edit file name'}
						placement='top'
						arrow
					>
						<Stack direction='row' alignItems={'center'}>
							{!row.isFolder && specialChars.test(row.name) && (
								<Tooltip
									title={
										'File name contains characters that could cause an error'
									}
								>
									<Warning color='warning' sx={{ fontSize: '1.2em' }} />
								</Tooltip>
							)}
							<IconButton
								color='primary'
								{...(row.isFolder
									? {
										onClick: () => setActiveFolder(row),
										sx: { color: '#fbce3a' },
									  }
									: {
										href: `${row.webUrl}?web=1`,
										target: '_blank',
										rel: 'noopener noreferrer',
										sx: { color: folioBlue },
									  })}
							>
								{row.isFolder ? <FolderIcon /> : <DownloadForOfflineOutlinedIcon />}
							</IconButton>

							{row.isFolder ? (
								<Link
									component='button'
									underline='hover'
									variant='body1'
									color={'inherit'}
									onClick={() => setActiveFolder(row)}
									noWrap
								>
									{name}
								</Link>
							) : (
								<Typography noWrap variant='body1'>
									{name}
								</Typography>
							)}
						</Stack>
					</Tooltip>
				);
			},
			renderEditCell: (params) => <DataGridMultilinePopper {...params} />,
		},
		{
			field: 'type',
			sortable: true,
			headerName: 'File type',
			align: 'right',
			width: 85,
			resizable: false,
			display: 'flex',
			renderHeader: (params) => <strong>TYPE</strong>,
			renderCell: ({ row }) => {
				return row.isFolder ? (
					<></>
				) : (
					<Typography noWrap variant='body1'>
						{row.type}
					</Typography>
				);
			},
		},
		{
			field: 'lastModifiedTimeUnix',
			sortable: true,
			headerName: 'Last modified',
			align: 'right',
			flex: 1,
			minWidth: 200,
			display: 'flex',
			renderHeader: (params) => <strong>LAST MODIFIED</strong>,
			renderCell: ({ row }) => (
				<Typography noWrap variant='body1'>
					{row.lastModifiedDateTime
						? formatDate(new Date(row.lastModifiedDateTime), true)
						: ''}
				</Typography>
			),
		},
		{
			field: 'createdDateTimeUnix',
			sortable: true,
			headerName: 'Date added',
			align: 'right',
			flex: 1,
			minWidth: 200,
			display: 'flex',
			renderHeader: (params) => <strong>DATE ADDED</strong>,
			renderCell: ({ row }) => (
				<Typography noWrap variant='body1'>
					{row.createdDateTime ? formatDate(new Date(row.createdDateTime), true) : ''}
				</Typography>
			),
		},
		{
			field: 'Description',
			headerName: 'Description',
			flex: 2,
			minWidth: 200,
			editable: true,
			display: 'flex',
			renderHeader: (params) => <strong>DESCRIPTION</strong>,
			renderCell: ({ row }) => {
				const empty = (row.Description ?? '').length === 0;
				return (
					<span style={{ ...(empty && { color: 'rgba(0,0,0,0.25)' }) }}>
						{empty && !row.isFolder ? 'Please enter a description' : row.Description}
					</span>
				);
			},
			renderEditCell: (params) => <DataGridMultilinePopper {...params} />,
		},
	];

	const { getSharepointFileMetadata, updateFileMetadata } = useSharepoint(
		sharepoint?.sharepointSite,
		sharepoint?.clientSiteSuffix,
		sharepoint?.clientFolder,
		sharepoint?.clientSite
	);

	const handleAddMetadataFileDocument = async (metadata, url) => {
		if (sharepoint?.isValid) {
			await updateFileMetadata(url, metadata);
		}
	};

	useEffect(() => {
		const updateFiles = async () => {
			dispatch(setLoading(true));
			await Promise.all(
				files.map(async (f) => {
					try {
						const listItem = await getListItem(f.parentReference.driveId, f.id);
						const url = listItem.webUrl.split('/sites/');
						const serverRelativeUrl = `/sites/${url[url.length - 1]}`;
						const metadata = f.isFolder
							? {}
							: await getSharepointFileMetadata(serverRelativeUrl);
						return {
							...f,
							Description: metadata?.OData__x005f_ExtendedDescription ?? '',
							metadata,
							serverRelativeUrl,
						};
					} catch (error) {
						return f;
					}
				})
			).then((res) => {
				dispatch(setRows(res));
			});
			dispatch(setLoading(false));
		};
		updateFiles();
	}, [files]);

	const displayedFiles = useMemo(() => {
		// const display = rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

		const search = searchValue.toLowerCase().trim();
		const filesToDisplay =
			!showNewFoldersFeature && search.length > 0
				? rows.filter(
					(file) =>
						file.name.toLowerCase().includes(search) ||
							file.Description.toLowerCase().includes(search)
				  )
				: rows;
		return filesToDisplay;
		// return showNewFoldersFeature ? filesToDisplay : stableSort(filesToDisplay, getComparator('desc', 'createdDateTimeUnix'));
	}, [rows, searchValue, showNewFoldersFeature]);

	const updateDescription = async (newRow, oldRow) => {
		const metadata = {
			OData__ExtendedDescription: newRow.Description,
		};
		console.log('Update description', metadata, oldRow.serverRelativeUrl);
		await handleAddMetadataFileDocument(metadata, oldRow.serverRelativeUrl);
	};

	const updateFileName = async (newRow, oldRow) => {
		const cleanedName = newRow.name.replace(specialChars, '');
		const metadata = {
			FileLeafRef: `${cleanedName}.${oldRow.type}`,
		};
		console.log('Update file name', metadata, oldRow.serverRelativeUrl);
		await handleAddMetadataFileDocument(metadata, oldRow.serverRelativeUrl);
	};

	const processRowUpdate = async (newRow, oldRow) => {
		if (oldRow.Description !== newRow.Description) {
			try {
				await updateDescription(newRow, oldRow);
				dispatch(
					showToast({
						message: 'Description updated!',
						success: true,
					})
				);
			} catch (error) {
				const errorMessage = error?.response?.data?.error?.message?.value ?? '';
				console.log('🙅 ~ ERROR UPDATING METADATA', error);
				dispatch(
					showToast({
						message: `Failed to update description: ${errorMessage}`,
						error: true,
					})
				);
			}
			dispatch(triggerRefresh());
		}
		if (oldRow.name !== newRow.name) {
			try {
				await updateFileName(newRow, oldRow);
				dispatch(
					showToast({
						message: 'File name updated!',
						success: true,
					})
				);
			} catch (error) {
				const errorMessage = error?.response?.data?.error?.message?.value ?? '';
				console.log('🙅 ~ ERROR UPDATING METADATA', error);
				dispatch(
					showToast({
						message: `Failed to update file name: ${errorMessage}`,
						error: true,
					})
				);
			}
			dispatch(triggerRefresh());
		}
		return newRow;
	};

	const handleProcessRowUpdateError = (error) => {
		console.log('Error updating row', error);
	};

	const handleSearch = () => {
		if (searchText != searchValue) {
			dispatch(setRows([]));
			dispatch(setItemId(breadcrumbs[0].id));
			dispatch(setBreadcrumbs([breadcrumbs[0]]));
			dispatch(setSearchValue(searchText));
		}
	};

	useEffect(() => {
		handleReset();
	}, []);

	return (
		<Box sx={{ width: '100%' }}>
			<Box width={{ sm: '100%', md: '60%' }} pb='1em'>
				{showNewFoldersFeature ? (
					<SearchField
						value={searchText}
						disabled={loading}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleSearch();
							}
						}}
						onChange={(e) => dispatch(setSearchText(e.target.value))}
						onClick={handleSearch}
						onReset={handleReset}
					/>
				) : (
					<SearchField
						value={searchValue}
						onChange={(event) => dispatch(setSearchValue(event.target.value))}
						onReset={() => dispatch(setSearchValue(''))}
						helperText={''}
					/>
				)}
			</Box>
			{showNewFoldersFeature && <FolderBreadcrumbs />}
			{!allLoading && !sharepoint?.isValid ? (
				<Typography variant='subtitle'>
					{`Could not find valid Sharepoint site for ${
						deal ? 'deal' : 'client'
					} owner. Please contact support@folio.insure.`}
				</Typography>
			) : (
				<DataGrid
					loading={allLoading}
					autoHeight
					width='100%'
					getRowHeight={() => 'auto'}
					initialState={{
						sorting: {
							sortModel: [{ field: 'createdDateTimeUnix', sort: 'desc' }],
						},
					}}
					sortingOrder={['desc', 'asc']}
					sx={{
						backgroundColor: 'white',
						'& .MuiDataGrid-editInputCell': {
							backgroundColor: 'transparent',
						},
						'--DataGrid-overlayHeight': allLoading ? '5em' : '3em',
					}}
					isCellEditable={(params) => {
						if (params.row.isFolder) {
							return false;
						}
						return params.colDef?.editable;
					}}
					processRowUpdate={processRowUpdate}
					onProcessRowUpdateError={handleProcessRowUpdateError}
					rows={displayedFiles}
					columns={columns}
					disableRowSelectionOnClick
					disableSelectionOnClick
					disableDensitySelector
					// disableColumnFilter
					// disableColumnSelector
					disableColumnMenu
					slots={{
						noRowsOverlay: allError ? NoRowsErrorOverlay : NoRowsOverlay,
						...(showNewFoldersFeature && { toolbar: AttachmentsToolbar }),
					}}
					slotProps={{
						loadingOverlay: {
							variant: 'skeleton',
							noRowsVariant: 'skeleton',
						},
					}}
					sortModel={sortModel}
					onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
				/>
			)}
		</Box>
	);
};
