import { Avatar, Stack, Typography } from '@mui/material';
import React from 'react';

export const ClientNameAndAvatar = ({title, subtitle, initials, width}) => {
	
	return <Stack spacing={1} width='100%'>
		<Typography  variant="avatar_title">
			{title}
		</Typography>
		<Typography variant="avatar_subtitle">
			{subtitle}
		</Typography>
	</Stack>;
};