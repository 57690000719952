import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/styles/index.scss';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import App from 'app/app';
import store from 'app/store';
import { msalConfig } from 'app/authConfig';
import reportWebVitals from 'reportWebVitals';
import Hotjar from '@hotjar/browser';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Initialise Hotjar for recording
if ((process.env.REACT_APP_HOTJAR_ID ?? '').length > 0) {
	Hotjar.init(process.env.REACT_APP_HOTJAR_ID, 6);
}


const msalInstance = new PublicClientApplication(msalConfig);

root.render(
	<React.StrictMode>
		<MsalProvider instance={msalInstance}>
			<Provider store={store}>
				<App />
			</Provider>
		</MsalProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
