import React, { useEffect, useMemo, useState } from 'react';
import {
	Box,
	FormLabel,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	Chip,
	Tooltip,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import { useRefinementList } from 'react-instantsearch';
import { theme } from '@/app/theme';
import { folioBlue, getPipelineIcon } from '@/utils/constants';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { getComparator, stableSort } from '@/components/table/functions';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '@/features/table/instantSearchSlice';
import { useLocation } from 'react-router-dom';
import { useGetOwnersByIdsQuery } from '@/features/user/ownerApi';

export const RefinementCheckboxList = ({
	title,
	disabled,
	multiple,
	width = '150px',
	pipeline,
	...props
}) => {
	const { pathname: slug } = useLocation();
	// const slug = useMemo(() => {
	// 	const names = pathname.split('/');

	// 	return `/${names[0]}`;
	// }, [pathname]);

	const dispatch = useDispatch();

	const [expanded, setExpanded] = useState(false);

	const { filters: instantSearchFilters } = useSelector((state) => state.instantSearch);
	const filters = useMemo(() => instantSearchFilters[slug] ?? {}, [instantSearchFilters, slug]);
    
    
	const currentFilters = useMemo(() => filters[props.attribute] ?? [], [filters, props.attribute]);

	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	const pipelineQuery = useGetPipelinePropertiesQuery(pipeline);

	const pipelines = useMemo(
		() => {
			const data = pipelineQuery?.data?.pipelines?.results ?? [];
			const uniqueValues = Array.from(new Set(data.map(p => p.displayOrder)));
			return data.length === uniqueValues.length ? data.slice() : data.map(p => ({ 
				...p, 
				displayOrder: data.findIndex(r => r.id === p.id),
			}));
		},
		[pipelineQuery?.data?.pipelines?.results]
	);

	const stages = useMemo(
		() =>
			pipelineQuery?.data?.pipelines?.stages ??
			(pipelineQuery.data?.pipelines?.results ?? []).flatMap((r) =>
				r?.stages.map((s) => ({ ...s, pipeline: r?.label }))
			) ??
			[],
		[pipelineQuery.data?.pipelines]
	);

	const { items, refine } = useRefinementList(props);

	const userData = useGetOwnersByIdsQuery(items.map(i => i.value), {
		skip: props.attribute != 'ownerId'
	});

	const refactoredItems = useMemo(() => {
		const itemsWithStages = items.map((item) => {
			const stage = stages.find((s) => s.id == item.value);
			const user = (userData.data ?? []).find((s) => s.id == item.value);
            
			const pipelineData = pipelines.find((s) => s.id == item.value || s.id == pipeline || (stage && stage.pipeline == s.label));
			return {
				...item,
				...(pipelineData && {
					label: pipelineData.label,
					order: pipelineData.displayOrder,
					pipelineDisplayOrder: pipelineData.displayOrder,
				}),
				...(stage && {
					label: stage.label,
					pipeline: stage.pipeline,// ?? pipeline,
					order: stage.displayOrder,
				}),
				...(user && {
					label: user.firstName ? `${user.firstName} ${user.lastName}` : user.email,
				}),
			};
		});

		const comparator = (a, b) => {
			const [a1, a2] = [a.pipelineDisplayOrder, a.order];
			const [b1, b2] = [b.pipelineDisplayOrder, b.order];
			return a1 - b1 || a2 - b2;
		};

		return Object.keys(stages).length > 0 || pipelines.length > 0
			? 
			itemsWithStages.slice().sort(comparator)
			: items;
	}, [items, stages, pipeline, pipelines, userData.data]);

	const all = useMemo(
		() => ({
			label: `Clear ${title} filters`,
			value: 'Clear',
			isRefined: false, //refactoredItems.every((item) => item.isRefined),
		}),
		[refactoredItems, title]
	);

	const refinedItems = items.filter((i) => i.isRefined);

	const allItems = useMemo(
		() =>
			multiple && refinedItems.length > 0 ? [all, ...refactoredItems] : [...refactoredItems],
		[refactoredItems, refinedItems]
	);

	const haveSameContents = (a, b) =>
		a.length === b.length &&
		[...new Set([...a.slice(), ...b.slice()])].every(
			(v) => a.filter((e) => e === v).length === b.filter((e) => e === v).length
		);

	useEffect(() => {
		const refined = refinedItems.map((v) => v.value);
		console.log(`CURRENT FILTERS ${props.attribute}`, currentFilters, 'refined', refined);

		const isEmpty = currentFilters.length === 0;

		if (haveSameContents(refined, currentFilters)) {
			return;
		}

		const changes = currentFilters.filter((i) => !refined.includes(i));
		const deletions = refined.filter((i) => !currentFilters.includes(i));
		console.log('CHANGES', changes, 'DELETIONS', deletions);

		if (isEmpty && refined.length > 0) {
			refined.forEach((i) => refine(i));
		} else if (changes.length > 0) {
			changes.forEach((i) => refine(i));
		} else if (deletions.length > 0) {
			deletions.forEach((i) => refine(i));
		}
	}, [currentFilters, refinedItems]);

	useEffect(() => {
		if (refinedItems.length > 0) {
			setExpanded(true);
		}
	}, [refinedItems]);

	const handleChange = (item) => {
		console.log('NEW SELECTION', item);
		// const refined = refinedItems.map((v) => v.value);
		if (multiple) {
			if (['All', 'Clear', 'None'].includes(item.value)) {
				dispatch(setFilters({ slug, type: props.attribute, values: [] }));
			} else {
				// var multi = item.reduce((o, n) => {
				// 	n in o ? (o[n] += 1) : (o[n] = 1);
				// 	return o;
				// }, {});

				// var res = Object.keys(multi).filter((k) => multi[k] == 1);
				const multi = currentFilters.includes(item.value)
					? [...currentFilters.slice().filter((v) => v != item.value)]
					: [...currentFilters.slice(), item.value];
				console.log('MULTI', multi, 'FILTERS', currentFilters);
				dispatch(setFilters({ slug, type: props.attribute, values: multi }));
				// refine(item.value);
			}
		} else {
			if (item.value == 'None') {
				// refined.forEach((item) => refine(item));
				dispatch(setFilters({ slug, type: props.attribute, values: [] }));
			} else {
				// refine(item.value);
				dispatch(
					setFilters({
						slug,
						type: props.attribute,
						values: currentFilters.includes(item.value) ? [] : [item.value],
					})
				);
			}
		}
	};

	return (
		<Accordion
			elevation={0}
			disableGutters
			square
			expanded={expanded}
			onChange={() => setExpanded((prev) => !prev)}
			sx={{
				backgroundColor: 'transparent',
				'&:not(:last-child)': {
					borderBottom: 0,
				},
				'&::before': {
					display: 'none',
				},
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls='panel1-content'
				id='panel1-header'
			>
				<Stack
					direction='row'
					justifyContent={'space-between'}
					width={'100%'}
					alignItems={'center'}
				>
					<FormLabel sx={{ minimalLabelTheme, color: folioBlue }}>{title}</FormLabel>
					{refinedItems.length > 0 && (
						<Typography
							variant='task_updated'
							sx={{ fontWeight: 'bold' }}
						>{`(${refinedItems.length})`}</Typography>
					)}
				</Stack>
			</AccordionSummary>
			<AccordionDetails>
				<FormGroup
					sx={{ width: '100%', '& .MuiFormControlLabel-label': { width: '100%' } }}
				>
					{allItems.map((item, index) => {
						const isClearOption = item.value.toUpperCase() === 'CLEAR';
						return (
							<FormControlLabel
								key={`${index}-${item.label}`}
								sx={{ width: '100%' }}
								control={
									<Checkbox
										checked={item.isRefined}
										onChange={() => handleChange(item)}
										{...(isClearOption && { icon: <ClearIcon /> })}
									/>
								}
								label={
									<Stack
										direction='row'
										justifyContent={'space-between'}
										alignItems={'center'}
										width='100%'
									>
										<span
											{...(isClearOption && { style: { fontWeight: 500 } })}
										>
											{item.label}
										</span>
										{pipelines && item.pipeline && (
											<Chip
												size={'small'}
												// color={colour}
												// sx={{
												//     backgroundColor: colour,
												//     color: 'rgb(255, 255, 255)'
												// }}
												label={item.pipeline ?? item.pipeline?.label ?? ''}
											/>
										)}
									</Stack>
								}
							/>
						);
					})}
				</FormGroup>
			</AccordionDetails>
		</Accordion>
	);
};
