/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo, useState } from 'react';
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	Stack,
	TablePagination,
	Typography,
} from '@mui/material';
import { FolioTableHead } from '@/components/table/FolioTableHead';
import { PolicyTransactionRow } from '@/components/tables/PolicyTransactionRow';
import { TableProgressIndicator } from '@/components/table/TableProgressIndicator';
import { determineContext } from '@/hooks/determineContext';
import { AddNewBusinessButton } from '@/components/buttons/AddNewBusinessButton';
import { InstantSearch, Configure } from 'react-instantsearch';
import { dealClient} from '@/utils/typesense';
import { InstantSearchFieldAdaptor } from '@/components/SearchField';
import { HitsTableBody } from '@/components/table/HitsTableBody';
import { HitsTablePagination } from '@/components/table/TablePaginationActions';
import { rowLimits } from '@/components/table/data';

const policyHeadCells = [
	{ id: 'dealname', numeric: false, label: 'Deal Name', align: 'left', sortable: false },
	{ id: 'policies', numeric: false, label: 'Policies', align: 'left', sortable: false },
	{ id: 'status', numeric: false, label: 'Status', align: 'left', sortable: false },
	{ id: 'pipeline', numeric: false, label: 'Pipeline', align: 'left', sortable: false },
	{ id: 'button', numeric: false, label: '', align: 'left', sortable: false },
];

export const PolicyTransactionTab = () => {
	const { deals, claims, loading, client } = determineContext();

	const [order, setOrder] = useState('desc');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const isLoading = loading?.client || loading?.deals;

	const handleChangePage = (event, newPage) => setPage(newPage);
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// const reducedMergedDeals = useMemo(() => {
	// 	const mergedDeals = [...deals, ...(claims || [])];
	// 	return mergedDeals.flatMap((deal) => ({
	// 		deal,
	// 		policy: deal?.policyResults?.[0]?.policy || null,
	// 		transaction: deal?.policyResults?.[0]?.transaction || null,
	// 	}));
	// }, [deals, claims]);

	
	const filterSchema = useMemo(() => {
		const clientFilter = `clientHubspotId := ${client?.hs_object_id}`;
		const pipelineFilter = `pipeline :!= ${process.env.REACT_APP_PIPELINE_CLAIMS}`;
		const schema = {
		  filters: `${clientFilter} && ${pipelineFilter}`,
		};
		console.log('Filters:', schema.filters); 
		return schema;
	  }, [client]);

	return (
		<InstantSearch indexName="deals" searchClient={dealClient}>
			<Configure {...filterSchema} />
			<TableContainer component={Paper}>
				<Stack direction="row" justifyContent="space-between" p={1} spacing={2}>
					<Box sx={{maxWidth: 600, width: '100%' }}> 
						<InstantSearchFieldAdaptor/>
					</Box>
					<AddNewBusinessButton />
				</Stack>
				<Table>
					<FolioTableHead order={order} orderBy="dealname" headCells={policyHeadCells} />
					<HitsTableBody
						TableRowComponent={
							PolicyTransactionRow
						}
						headCells={policyHeadCells}
						order={order}
						orderBy={'dealname'}
						includePolicies={true}
						ErrorRowComponent={() => (
							<TableRow>
								<TableCell colSpan={policyHeadCells.length} align='center'>
									<Typography variant='subtitle1'>
											There was an error. Please try again.
									</Typography>
								</TableCell>
							</TableRow>
						)}
						EmptyRowComponent={() => (
							<TableRow>
								<TableCell colSpan={policyHeadCells.length} align='center'>
									<Typography variant='subtitle1'>
										{'No deals'}
									</Typography>
								</TableCell>
							</TableRow>
						)}
					/>
				</Table>
				{/* <TablePagination
					rowsPerPageOptions={[20, 50, 100]}
					component="div"
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/> */}
				<HitsTablePagination
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					items={rowLimits.map((item) => ({
						label: `${item} rows per page`,
						value: item,
						default: item == rowsPerPage,
					}))}
				/>
			</TableContainer>
		</InstantSearch>
	);
};
