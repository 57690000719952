/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo, useState } from 'react';
import {
	TableCell,
	TableRow,
	Typography,
	Box,
	Checkbox,
	Chip,
	CircularProgress,
	Skeleton,
	Button,
	Stack,
	Fade,
} from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { ClientNameAndAvatar } from '@/components/table/ClientNameAndAvatar';
import { formatDate, removeHtml, getUrlFromPipeline } from '@/utils/constants';
import { PriorityChip } from '@/components/PriorityChip';
import { useUpdateTaskMutation } from '@/features/engagements/engagementsApi';
import { isAfter } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import ChecklistIcon from '@mui/icons-material/Checklist';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { paths } from '@/app/routes';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { openDialog } from '@/features/dialog/dialogSlice';

const today = new Date();

const TaskTypeChip = ({ taskType }) => {
	return (
		<Chip
			icon={
				taskType == 'CALL' ? (
					<PhoneIcon size='small' />
				) : taskType == 'EMAIL' ? (
					<EmailIcon size='small' />
				) : (
					<ChecklistIcon size='small' />
				)
			}
			size='small'
			label={taskType}
		/>
	);
};

export const TaskRow = ({ task }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const deal = task.deal;
	const ticket = task.ticket;
	const client = task.client;
	const contact = task.contact;

	const [updateTask, { isLoading: isTaskUpdateLoading }] = useUpdateTaskMutation();

	const _completeTask = async () => {
		const id =
			deal?.hs_object_id ??
			ticket?.hs_object_id ??
			client?.hs_object_id ??
			contact?.hs_object_id;
		// const id = deal ? deal.hs_object_id : ticket?.hs_object_id;
		const objectType = deal
			? 'deal'
			: ticket
			? 'ticket'
			: client
			? 'client'
			: contact
			? 'contact'
			: null;
		await updateTask({
			dealId: id,
			taskId: task.id,
			objectType,
			properties: {
				hs_task_status: task.completed ? 'NOT_STARTED' : 'COMPLETED',
			},
		}).unwrap();
	};

	const { data: owner } = useGetOwnerQuery(
		{ ownerId: task.hubspot_owner_id },
		{ skip: !task.hubspot_owner_id }
	);

	const getDealInitials = (object) => {
		if (!object) {
			return 'NA';
		}
		let dealName;
		if (object?.firstname && object?.lastname) {
			dealName = `${object.firstname} ${object.lastname}`;
		} else {
			dealName = object.insuredName ?? object.name ?? '';
		}
		const names = dealName.split(' ');

		if (names.length === 2) {
			return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
		} else {
			return dealName.length >= 2
				? dealName.substring(0, 2).toUpperCase()
				: dealName.toUpperCase();
		}
	};

	const pipelineId = deal ? deal.properties?.pipeline : ticket?.properties?.hs_pipeline;
	const { data: pipelineData, isLoading } = useGetPipelinePropertiesQuery(pipelineId, {
		skip: !pipelineId,
	});
	const pipelineName = pipelineData?.pipelines?.label;

	const truncatedDescription = useMemo(() => {
		const body = removeHtml(task.hs_task_body);
		const trunc = body.slice(0, 50);
		return task.hs_task_body.length > 50 ? `${trunc} ...` : trunc;
	});

	const timestamp = useMemo(() => new Date(task.hs_timestamp));
	const isOverdue = useMemo(() => isAfter(today, timestamp) && !task.completed);

	const handleNavigation = () => {
		const object = deal ?? ticket;
		if (object) {
			const path = getUrlFromPipeline(pipelineId);
			navigate(`${path}/${object.id}`, { state: { task: task.id } });
		} else if (client) {
			navigate(`${paths.clients}/${client.hs_object_id}`, { state: { task: task.id } });
		} else if (contact) {
			navigate(`${paths.contacts}/${contact.hs_object_id}`, { state: { task: task.id } });
		}
	};

	const [hoveredRow, setHoveredRow] = useState(null);

	const handleMouseEnter = () => setHoveredRow(true);
	const handleMouseLeave = () => setHoveredRow(null);

	const handleTaskOpen = () => {
		dispatch(openDialog({ dialogName: 'task', data: { task } }));
	};

	return (
		<TableRow
			hover
			key={`tasks-table-row-${task.id}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={handleNavigation}
			style={{ cursor: (deal ?? ticket ?? client ?? contact) ? 'pointer' : 'default' }}
		>
			<TableCell>
				{isTaskUpdateLoading ? (
					<Box padding='0.76rem'>
						<CircularProgress size='1rem' />
					</Box>
				) : (
					<Checkbox
						icon={
							<>
								<svg width={0} height={0}>
									<linearGradient id='linearColors' x1={0} y1={1} x2={1} y2={1}>
										<stop offset={0} stopColor='rgba(131,214,255,1)' />
										<stop offset={1} stopColor='rgba(229,132,255,1)' />
									</linearGradient>
								</svg>
								<CheckCircleOutlineRoundedIcon
									sx={{ fill: 'url(#linearColors)' }}
								/>
							</>
						}
						checkedIcon={
							<>
								<svg width={0} height={0}>
									<linearGradient id='linearColors2' x1={0} y1={1} x2={1} y2={1}>
										<stop offset={0} stopColor='rgba(131,214,255,1)' />
										<stop offset={1} stopColor='rgba(229,132,255,1)' />
									</linearGradient>
								</svg>
								<CheckCircleRoundedIcon sx={{ fill: 'url(#linearColors2)' }} />
							</>
						}
						checked={task.completed}
						onChange={_completeTask}
					/>
				)}
			</TableCell>
			<TableCell width={300}>
				<Stack
					direction='row'
					spacing={3}
					alignItems={'center'}
					justifyContent={'space-between'}
					width='100%'
				>
					<Typography {...(task.completed && { sx: { textDecoration: 'line-through' } })}>
						{task.hs_task_subject}
					</Typography>

					<Fade in={hoveredRow} orientation='horizontal'>
						<Button
							onClick={(e) => {
								e.stopPropagation();
								handleTaskOpen();
							}}
							variant='contained'
							size='small'
						>
							Edit
						</Button>
					</Fade>
				</Stack>
			</TableCell>
			<TableCell>
				<ClientNameAndAvatar
					title={
						deal?.insuredName ??
						ticket?.insuredName ??
						client?.name ??
						(contact?.firstname ? `${contact.firstname} ${contact.lastname}` : null) ??
						'-'
					}
					subtitle={deal?.description ?? deal?.dealName ?? ticket?.dealName ?? ''}
					initials={getDealInitials(deal ?? ticket ?? client)}
					width={250}
				/>
			</TableCell>
			<TableCell align='left' width={80}>
				<TaskTypeChip taskType={task.hs_task_type ?? 'TODO'} />
			</TableCell>
			<TableCell width={80}>
				{isLoading ? (
					<Skeleton variant='text' animation='wave' />
				) : (
					pipelineName ?? (client ? 'Client Task' : contact ? 'Prospect Task' : '')
				)}
			</TableCell>
			<TableCell align='right'>
				{`${owner?.firstName ?? '-'} ${owner?.lastName ?? ''}`}
			</TableCell>
			<TableCell align='right' width={150}>
				{formatDate(task.hs_lastmodifieddate)}
			</TableCell>
			<TableCell align='right' width={150}>
				<Typography
					sx={{
						...(isOverdue && { fontWeight: 600, color: 'red' }),
					}}
				>
					{formatDate(task.hs_timestamp)}
				</Typography>
			</TableCell>
		</TableRow>
	);
};
