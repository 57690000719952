/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { Breadcrumbs, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { setItemId, setBreadcrumbs } from '@/features/table/attachmentsTableSlice';

export const FolderBreadcrumbs = () => {
	const dispatch = useDispatch();

	const { breadcrumbs } = useSelector((state) => state.attachmentsTable);

	return (
		<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ mb: '0.5em' }}>
			{breadcrumbs.map((b, i) => {
				return (
					<Link
						component='button'
						key={`breadcrumb-${b.id}`}
						underline="hover"
						variant='task_updated'
						color={i !== breadcrumbs.length - 1 ? 'inherit' : 'textPrimary'}
						onClick={() => {
							if (i !== breadcrumbs.length - 1) {
								dispatch(setItemId(b.id));
								const newBreadcrumbs = breadcrumbs.slice(0, i + 1);
								dispatch(setBreadcrumbs(newBreadcrumbs));
							}
						}}
					>
						{b.name}
					</Link>
				);
			})}
		</Breadcrumbs>
	);
};
