import React, { useMemo } from 'react';
import {
	Box,
	Button,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	Typography,
	FormControl,
	FormHelperText,
	CircularProgress,
	FormLabel,
	Container,
	InputAdornment,
	RadioGroup,
	FormControlLabel,
	Radio,
	Select,
	MenuItem,
	Tooltip,
	Link,
	Accordion,
	AccordionSummary,
	FormGroup,
	Checkbox,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
	CLIENT_CATEGORY,
	CLIENT_CATEGORY_LABELS,
} from '@/components/forms/CreateNewBusinessForm/validationSchema';
import { ExpandMore } from '@mui/icons-material';
import { determineContext } from '@/hooks/determineContext';
import { folioBlue } from '@/utils/constants';
import { ClientCheckbox } from '@/components/buttons/ClientCheckbox';
import { useUpdateClientRowMutation, useUpdateInsightClientMutation } from '@/features/clients/clientsApi';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useSelector } from 'react-redux';

export const ClientCategory = () => {
	const { client } = determineContext();
	const clientCategory = useMemo(() => client?.clientRow?.[CLIENT_CATEGORY] ?? [], [client]);

	const [updateClientRow, { isLoading }] = useUpdateClientRowMutation();
	const [updateInsightClient] = useUpdateInsightClientMutation();

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const { data: userDetails } = useGetUserDetailsQuery(
		{ email },
		{ skip: !email }
	);
	const hubspotId = userDetails?.hubspotId;

	const onUpdateClientRow = async (newValues) => {
		await updateClientRow({
			clientId: client?.hs_object_id,
			docId: client?.clientRow?.docId,
			properties: { [CLIENT_CATEGORY]: newValues }
		}).unwrap();

		const properties = {
			VulnerableClient: newValues.includes('vulnerableClient') ? 'Yes' : 'No',
			ComplianceTypeId: newValues.includes('wholesaleClient') ? 2 : 1
		};
		await updateInsightClient({
			properties,
			ownerId: hubspotId,
			clientId: client?.id ?? client?.hs_object_id,
			insightId: client?.insight?.Id,
		}).unwrap();
	};

	const onChange = (newValues) => {
		console.log('New value: ', newValues);
		onUpdateClientRow(newValues);
	};

	return (
		<FormGroup width='100%'>
			<Typography variant="form_label">Client category</Typography>
			<Grid container>
				{Object.entries(CLIENT_CATEGORY_LABELS).map(([value, label]) => (
					<Grid size={{ xs: 12, sm: 6 }} key={`client-category-${value}`}>
						<FormControlLabel
							control={
								<ClientCheckbox
									value={value}
									valuesArray={clientCategory}
									setValues={onChange}
									disabled={isLoading}
								/>
							}
							label={label}
							sx={{
								'& .MuiTypography-root': {
									fontSize: '12px'
								},
								'& .MuiButtonBase-root': {
									fontSize: '18px'
								},
							}}
						/>
					</Grid>
				))}
			</Grid>
		</FormGroup>
	);
};
