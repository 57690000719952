import React, { useMemo } from 'react';
import { setFilters } from '@/features/table/instantSearchSlice';
import { Chip, Stack, Tooltip, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { getComparator, stableSort } from '@/components/table/functions';
import { getPipelineFromSlug } from '@/utils/constants';
import { useLocation } from 'react-router-dom';
import { useGetOwnersByIdsQuery } from '@/features/user/ownerApi';

const RefinementListChips = ({ attribute, title, index }) => {
	const dispatch = useDispatch();
	const { pathname: slug } = useLocation();
	// const slug = useMemo(() => {
	// 	const names = pathname.split('/');
	// 	return `/${names[0]}`;
	// }, [pathname]);

	const { filters: instantSearchFilters } = useSelector((state) => state.instantSearch);
	const filters = useMemo(() => instantSearchFilters[slug] ?? {}, [instantSearchFilters, slug]);

	const currentFilters = filters[attribute] ?? [];

	const pipeline = useMemo(() => {
		const pathnames = window.location.pathname.split('/');
		const slug = pathnames[pathnames.length - 1];
		return getPipelineFromSlug(`/${slug}`);
	}, [window.location.pathname]);

	const pipelineQuery = useGetPipelinePropertiesQuery();

	const pipelines = useMemo(
		() => pipelineQuery?.data?.pipelines?.results ?? [],
		[pipelineQuery?.data?.pipelines?.results]
	);

	const stages = useMemo(
		() =>
			pipelineQuery?.data?.pipelines?.stages ??
			(pipelineQuery.data?.pipelines?.results ?? []).flatMap((r) =>
				r?.stages.map((s) => ({ ...s, pipeline: r?.label }))
			) ??
			[],
		[pipelineQuery.data?.pipelines]
	);

	const userData = useGetOwnersByIdsQuery(currentFilters, {
		skip: attribute != 'ownerId',
	});

	const refactoredItems = useMemo(() => {
		const itemsWithStages = currentFilters.map((item) => {
			const stage = stages.find((s) => s.id == item);
			const pipelineData = pipelines.find((s) => s.id == item);
			const user = (userData.data ?? []).find((s) => s.id == item);

			return {
				item,
				label: item,
				...(attribute == 'pipeline' &&
					pipelineData && {
					label: pipelineData.label,
					order: pipelineData.displayOrder,
				}),
				...(stage && {
					label: stage.label,
					pipeline: stage.pipeline ?? pipeline,
					order: stage.displayOrder,
				}),
				...(user && {
					label: user.firstName ? `${user.firstName} ${user.lastName}` : user.email,
				}),
			};
		});
		return itemsWithStages;
		// return stableSort(itemsWithStages, getComparator('asc', Object.keys(stages).length > 0 ? 'order' : 'label'));
	}, [currentFilters, stages, pipeline, pipelines]);

	const handleDelete = (item) => {
		const multi = currentFilters.includes(item)
			? [...currentFilters.slice().filter((v) => v != item)]
			: [...currentFilters.slice(), item];
		dispatch(setFilters({ slug, type: attribute, values: multi.map((i) => i) }));
	};

	const colour = ['primary', 'secondary', 'success', 'warning', 'info', 'error'][index ?? 0];

	return (
		<>
			{refactoredItems.map((i) => (
				<Chip
					size={'small'}
					color={colour}
					// sx={{
					//     backgroundColor: colour,
					//     color: 'rgb(255, 255, 255)'
					// }}
					label={
						<Tooltip title={`"${i.label}" (${title})`}>
							<Stack direction='row' fontSize={'12px'} alignItems='center'>
								{userData.isLoading || pipelineQuery.isLoading ? (
									<Skeleton
										variant='text'
										width='6em'
										sx={{ bgcolor: 'rgba(255, 255, 255, 0.25)' }}
									/>
								) : (
									<span
										style={{
											flexGrow: 1,
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{i.label}
									</span>
								)}
								<span
									style={{
										fontStyle: 'italic',
										fontSize: '11px',
										paddingLeft: '0.25em',
									}}
								>
									({title})
								</span>
							</Stack>
						</Tooltip>
					}
					onDelete={() => handleDelete(i.item)}
					key={`${attribute}-${i.label}-${i.item}`}
				/>
			))}
		</>
	);
};

export default RefinementListChips;
