/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	Typography,
	FormControl,
	FormHelperText,
	CircularProgress,
	FormLabel,
	Container,
	RadioGroup,
	FormControlLabel,
	Radio,
	Select,
	MenuItem,
	Tooltip,
	Link,
	Stack,
	FormGroup,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import '@/styles/fileuploader.scss';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getPipelineIcon, taskPriorities, yearFolderOptions } from '@/utils/constants';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateClientMutation } from '@/features/clients/clientsApi';
import {
	ADDRESS,
	ADVISER_FEE,
	CITY,
	CLIENT_ID,
	CLIENT_NAME,
	COUNTRY,
	DEAL_NAME,
	EMAIL,
	FILES,
	FIRST_NAME,
	INDUSTRY,
	initialValues as initValues,
	IS_EXISTING,
	LAST_NAME,
	OTHER_INFO,
	PHONE,
	PRIORITY,
	RENEWAL_DATE,
	validationSchema,
	WEBSITE,
	OWNER,
	COMPLETED_BY,
	SUBURB,
	POSTCODE,
	CLIENT_CATEGORY,
	SCOPE_OF_ADVICE,
	CLIENT_TYPE,
	COMMERCIAL,
	DOMESTIC,
	SCOPE_OF_ADVICE_LABELS_COMMERCIAL,
	SCOPE_OF_ADVICE_LABELS_DOMESTIC,
	ANNUAL_TURNOVER,
	NO_EMPLOYEES,
	IS_PROSPECT,
	CLIENT_CATEGORY_LABELS,
	YEAR_FOLDER,
	FULL_NEEDS_ANALYSIS,
} from './validationSchema';
import { InstantClientSelect } from '@/components/selects/ClientSelect';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { PageHeader } from '@/components/layouts/PageHeader';
import { showToast } from '@/features/toast/toastSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { IndustrySelect } from '@/components/ClientDetailEntry/industrySelect';
import FileUpload from '@/components/FileUpload';
import { Description, Warning } from '@mui/icons-material';
import { OwnerSelect } from '@/components/selects/OwnerSelect';
import { AddressFields } from './AddressFields';
import { ClientNameField } from './ClientNameField';
import { ContactFields } from './ContactFields';
import { AdditionalScopeFields } from './AdditionalScopeFields';
import { paths } from '@/app/routes';
import { useCreateNewBusinessMutation } from '@/features/deals/dealsApi';
import { ClientCheckbox } from '@/components/buttons/ClientCheckbox';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

const minDate = new Date();

const CreateNewBusinessForm = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const existingClient = location?.state?.client ?? {};
	const existingContact = location?.state?.contact ?? {};

	const isExistingClient = useMemo(
		() => Object.keys(existingClient).length > 0,
		[existingClient]
	);

	const isExistingContact = useMemo(
		() => Object.keys(existingContact).length > 0,
		[existingContact]
	);
	const account = useSelector((state) => state.msalAccount.account);

	const [formLoading, setFormLoading] = useState(false);

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'renewals' });
	const showNewRenewalsForm = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);



	const email = account?.username;
	const {
		data: userDetails,
		isLoading,
		isUninitialized,
		isError,
	} = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
	const hubspotId = userDetails?.hubspotId;

	const external = userDetails?.external;

	const canCreate = useMemo(
		() =>
			external
				? userDetails?.salesTeamId != null
				: userDetails?.salesTeamId != null && userDetails?.authorisedRepId != null,
		[userDetails]
	);

	const { setUpDealFilesAndFolders } = useClientStorageHooks(userDetails);

	const [createClient, { isNewClientLoading }] = useCreateClientMutation();
	const [createNewBusiness, { isDealLoading }] = useCreateNewBusinessMutation();

	const createNewClient = async () => {
		let clientId;
		if (!formik.values.isExisting) {
			const res = await createClient({
				completedBy: formik.values.completedBy,
				hubspotId: formik.values.owner,
				annualRevenue: formik.values.annualrevenue ?? 0,
				numberOfEmployees: formik.values.numberofemployees ?? 0,
				firstName: formik.values.firstName.trim(),
				lastName: formik.values.lastName.trim(),
				phoneNumber: formik.values.phoneNumber,
				email: formik.values.emailAddress.trim(),
				insuredName: formik.values.clientName.trim(),
				websiteAddress: formik.values.websiteAddress,
				physicalAddress: formik.values.address,
				city: formik.values.city,
				country: formik.values.country,
				postCode: formik.values.postcode,
				suburb: formik.values.suburb,
				industry: formik.values.industry,
				isProspect: formik.values.isProspect,
				clientCategory: formik.values.clientCategory,
				notes: formik.values.otherInfo
			})
				.unwrap()
				.catch((err) => {
					console.log('🙅 ~ Error creating client', err);
					let errorMessage = 'Error creating client';
					if (err?.data?.type === 'hubspotContact') {
						errorMessage += '. Please check email and/or phone and try again.';
					}
					if (err?.data?.type === 'insightClient') {
						errorMessage += `. Client already exists in Insight with the name: ${formik.values.clientName}.`;
					}
					return { error: { message: errorMessage } };
				});
			clientId = res.companyId;
		} else {
			clientId = formik.values.clientId;
		}

		let body = {
			clientId,
			completedBy: formik.values.completedBy,
			hubspotId: formik.values.owner,
			dealName: formik.values.dealName.trim(),
			notes: formik.values.otherInfo,
			adviserFee: formik.values.adviserFee,
			clientGoals: formik.values.goalsOfCover,
			clientCategory: formik.values.clientCategory,
			clientType: formik.values.clientType,
			needsAnalysis: formik.values.needsAnalysis,
			[FULL_NEEDS_ANALYSIS]: formik.values[FULL_NEEDS_ANALYSIS] ?? false,
			additionalProducts: formik.values.additionalSpecialistProducts,
			riskClasses: formik.values.scopeOfAdvice,
			priority: formik.values.priority.length > 0 ? formik.values.priority : null,
		};

		if (formik.values.renewalDate) {
			const renewalDate = formik.values.renewalDate;
			// Get the local time zone offset in minutes
			const timezoneOffset = renewalDate.getTimezoneOffset();
			// Adjust the date to UTC by adding the time zone offset
			renewalDate.setMinutes(renewalDate.getMinutes() - timezoneOffset);
			// Set the time to midnight UTC
			const timestamp = renewalDate.setUTCHours(0, 0, 0, 0);
			body.renewalDate = timestamp;
		}

		if (formik.values[CLIENT_CATEGORY].length > 0) {
			body = {
				...body,
				[CLIENT_CATEGORY]: formik.values[CLIENT_CATEGORY],
			};
		}

		if (formik.values[SCOPE_OF_ADVICE].length > 0) {
			body = {
				...body,
				[SCOPE_OF_ADVICE]: formik.values[SCOPE_OF_ADVICE],
			};
		}

		return await createNewBusiness(body)
			.unwrap()
			.catch((err) => {
				console.log('🙅 ~ Error creating new business', err);
				return { error: { message: 'Error creating new business deal' } };
			});
	};


	const initialValues = useMemo(() => {
		return {
			...initValues,
			[CLIENT_TYPE]: COMMERCIAL,
			[OWNER]: hubspotId,
			[COMPLETED_BY]: hubspotId,
			...(Object.keys(existingClient).length > 0 && {
				[IS_EXISTING]: true,
				[CLIENT_NAME]: existingClient.name,
				[CLIENT_ID]: existingClient.id ?? existingClient.hs_object_id,
			}),
			...(Object.keys(existingContact).length > 0 &&
				Object.keys(existingClient).length === 0 && {
				[FIRST_NAME]: existingContact.firstname,
				[LAST_NAME]: existingContact.lastname,
				[PHONE]: existingContact.phone ?? '',
				[EMAIL]: existingContact.email ?? '',
			}),
			...(!canCreate && {
				[IS_EXISTING]: true,
			}),
		};
	}, [existingClient, existingContact, userDetails]);

	const createNewBusinessFolders = async (dealId, noteId) => {
		try {
			const { documentId } = await setUpDealFilesAndFolders(
				dealId,
				noteId,
				formik.values.clientName,
				formik.values.dealName,
				process.env.REACT_APP_PIPELINE_NEW_BUSINESS,
				formik.values.files,
				null,
				formik.values.yearFolder
			);
		} catch (error) {
			console.log('Error', error);
			return { error: { message: 'Error creating new business folders' } };
		}
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			setFormLoading(true);
			console.log('📋 ~ Formik values', values);

			const createError = (message) => {
				setFieldValue('errorMessage', message ?? 'Error creating new business');
				setFieldValue('successMessage', '');
				setSubmitting(false);
				setFormLoading(false);
			};

			const res = await createNewClient();
			if (res?.error) {
				console.log('🙅 ~ Error creating new business', res?.error);
				createError(res?.error?.message);
				return;
			}

			const response = await createNewBusinessFolders(res.dealId, res.noteId);
			if (response?.error) {
				createError(response?.error?.message);
				dispatch(
					showToast({
						message: response?.error?.message ?? 'New business created',
						action: {
							path: `${paths.newBusiness}/${res.dealId}`,
							label: 'GO TO NEW BUSINESS',
						},
						autohide: false,
					})
				);
				return;
			}
			console.log('🚀 ~ Successfully created new Endorsement', res);
			dispatch(
				showToast({
					message: 'New business created!',
					action: {
						path: `${paths.newBusiness}/${res.dealId}`,
						label: 'GO TO NEW BUSINESS',
					},
					autohide: false,
				})
			);
			window.scrollTo(0, 0);
			window.history.replaceState({}, '');
			resetForm();
			setFieldValue('successMessage', 'Successfully created New Business');
			setFieldValue('errorMessage', '');
			setSubmitting(false);
			setFormLoading(false);
			setSubmitting(false);
			setFormLoading(false);
		},
	});

	const loading = formik.isSubmitting || isNewClientLoading || formLoading || isDealLoading;

	const { getError, getErrorMessage } = useFormikHelper(formik);

	// useEffect(
	// 	() => console.log('FORMIK VALUES', formik.values, 'FORMIK ERRORS', formik.errors),
	// 	[formik.values, formik.errors]
	// );

	return (
		<Container sx={{ pt: 3 }}>
			
			<Button
				variant='text'
				onClick={() => navigate(paths.newBusiness)}
				sx={{ color: 'black', fontWeight: 'bold', mb: '1em' }}
				startIcon={<KeyboardBackspaceOutlinedIcon />}
			>
				{'Back to New Business'}
			</Button>
			<form onSubmit={formik.handleSubmit}>
				<CardHeader
					disableTypography
					title={<PageHeader title={'Add New Business'} icon={getPipelineIcon(process.env.REACT_APP_PIPELINE_NEW_BUSINESS)}/>}
				/>
				<CardContent>
					<Typography variant='body1' paragraph>
							Fill out the following form to create your new business deal
					</Typography>
					{isLoading || isUninitialized ? (
						<CircularProgress />
					) : (
						<Grid container spacing={4}>
							<Grid size={8}>
								<FormLabel required>{'Deal name'}</FormLabel>
								<TextField
									fullWidth
									{...formik.getFieldProps(DEAL_NAME)}
									error={getError(DEAL_NAME)}
									helperText={getErrorMessage(DEAL_NAME)}
									disabled={loading}
									required
									name={DEAL_NAME}
								/>
								<FormHelperText>
								Enter a clear and friendly name for this business deal to help you easily identify it later.
								</FormHelperText>
							</Grid>
							{showNewRenewalsForm && (
								<Grid size={4}>
									<FormLabel>{'Year folder'}</FormLabel>
									<Select
										name={YEAR_FOLDER}
										value={formik.values.yearFolder}
										disabled={formik.isSubmitting}
										onChange={(e) =>
											formik.setFieldValue(YEAR_FOLDER, e.target.value)
										}
										fullWidth={true}
									>
										{yearFolderOptions.map((value) => {
											return (
												<MenuItem
													value={value}
													key={`year-folder-selection-${value}`}
												>
													{value}
												</MenuItem>
											);
										})}
									</Select>
									<FormHelperText>
									Specify the year this business deal applies to, keeping your records organized.
									</FormHelperText>
								</Grid>
							)}
							<Grid size={12} sx={{ paddingTop: '2em' }} container spacing={4}>
								<Grid size={formik.values[IS_EXISTING] ? 12 : 8}>
									<FormLabel>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<span style={{ paddingRight: '0.5em' }}>
												{'New or existing client'}
											</span>
											{!canCreate && (
												<Tooltip
													title={
														<React.Fragment>
															<span>
																{
																	'It looks like you do not have permission to create clients. Please contact '
																}
															</span>
															<Link
																href='mailto:support@folio.insure'
																target='_blank'
																rel='noopener'
																color='inherit'
															>
																{'support@folio.insure'}
															</Link>
															<span>{' for assistance.'}</span>
														</React.Fragment>
													}
													placement='right'
												>
													<Warning
														color='warning'
														sx={{ fontSize: '1.2em' }}
													/>
												</Tooltip>
											)}
										</Box>
									</FormLabel>
									<FormControl fullWidth>
										<RadioGroup
											row
											name={IS_EXISTING}
											value={formik.values.isExisting}
											onChange={(e) => {
												formik.setFieldValue(
													IS_EXISTING,
													e.target.value == 'true'
												);

												[
													CLIENT_NAME,
													CLIENT_ID,
													INDUSTRY,
													FIRST_NAME,
													LAST_NAME,
													EMAIL,
													PHONE,
													WEBSITE,
													ADDRESS,
													CITY,
													COUNTRY,
													POSTCODE,
													SUBURB,
												].forEach((i) => {
													formik.setFieldValue(i, initialValues[i]);
												});
											}}
										>
											<FormControlLabel
												value={false}
												disabled={
													formik.isSubmitting ||
														isExistingClient ||
														isExistingContact ||
														!canCreate
												}
												control={<Radio />}
												label='New client'
											/>
											<FormControlLabel
												value={true}
												disabled={
													formik.isSubmitting ||
														isExistingClient ||
														isExistingContact
												}
												control={<Radio />}
												label='Existing client'
											/>
										</RadioGroup>
									</FormControl>
								</Grid>
								{!formik.values.isExisting && (
									<Grid size={4}>
										<FormLabel>{'Client type'}</FormLabel>
										<FormControl fullWidth>
											<RadioGroup
												row
												name={IS_PROSPECT}
												value={formik.values[IS_PROSPECT]}
												onChange={(e) => {
													formik.setFieldValue(
														IS_PROSPECT,
														e.target.value == 'true'
													);
												}}
											>
												<FormControlLabel
													value={false}
													disabled={
														formik.isSubmitting ||
															isExistingClient ||
															isExistingContact
													}
													control={<Radio />}
													label='Client'
												/>
												<FormControlLabel
													value={true}
													disabled={
														formik.isSubmitting ||
															isExistingClient ||
															isExistingContact
													}
													control={<Radio />}
													label='Prospect'
												/>
											</RadioGroup>
										</FormControl>
									</Grid>
								)}
							</Grid>
							{formik.values.isExisting && (
								<Grid size={12}>
									<FormLabel required>{'Search Client'}</FormLabel>
									<InstantClientSelect
										clientId={formik.values[CLIENT_ID]}
										clientName={formik.values[CLIENT_NAME]}
										disabled={isExistingClient}
										setClient={(client) => {
											if (client) {
												console.log(
													'Here is the selected client: ',
													client
												);
												formik.setFieldValue(CLIENT_ID, client.id);
												formik.setFieldValue(CLIENT_NAME, client.name);
											} else {
												formik.setFieldValue(CLIENT_ID, null);
												formik.setFieldValue(CLIENT_NAME, null);
											}
										}}
									/>
									<FormHelperText error={getError(CLIENT_ID)}>
										{getErrorMessage(CLIENT_ID)}
									</FormHelperText>
								</Grid>
							)}
							{!formik.values.isExisting && (
								<>
									<Grid size={8}>
										<ClientNameField formik={formik} loading={loading} />
									</Grid>
									<Grid size={4}>
										<FormLabel>{'Assign to'}</FormLabel>
										<OwnerSelect
											initialId={formik.values.owner}
											disabled={formik.isSubmitting}
											onChange={(value) => {
												formik.setFieldValue(OWNER, value);
											}}
										/>
										<FormHelperText>
									Select the broker responsible for managing this business deal.
										</FormHelperText>
									</Grid>
									<Grid size={8}>
										<FormLabel>{'Client Category'}</FormLabel>
										<FormGroup row sx={{ width: '100%' }}>
											<Grid container width={'100%'}>
												{Object.entries(CLIENT_CATEGORY_LABELS).map((entry) => {
													const [value, label] = entry;
													return (
														<Grid size={{ xs: 6 }} key={`client-category-${value}`}>
															<FormControlLabel
																size='small'
																control={
																	<ClientCheckbox
																		value={value}
																		valuesArray={formik.values[CLIENT_CATEGORY]}
																		setValues={(newValues) => {
																			formik.setFieldValue(
																				CLIENT_CATEGORY,
																				newValues
																			);
																		}}
																		disabled={loading}
																	/>
																}
																label={label}
															/>
														</Grid>
													);
												})}
											</Grid>
										</FormGroup>
									</Grid>
										
									<Grid size={12}>
										<AddressFields formik={formik} loading={loading} />
									</Grid>
									<Grid size={{ xs: 12, md: 6 }}>
										<FormLabel>{'Website Address'}</FormLabel>
										<TextField
											fullWidth
											name={WEBSITE}
											{...formik.getFieldProps(WEBSITE)}
											error={getError(WEBSITE)}
											helperText={getErrorMessage(WEBSITE)}
											disabled={loading}
										/>
										<FormHelperText>
												Please provide a web address if you have a website
												or social media.
										</FormHelperText>
									</Grid>
									<Grid size={{ xs: 12, md: 6 }}>
										<FormLabel>{'Industry'}</FormLabel>
										<IndustrySelect
											initialValue={formik.values.industry}
											disabled={loading}
											onChange={(value) =>
												formik.setFieldValue('industry', value)
											}
										/>
									</Grid>
									<Grid size={{ xs: 12, md: 6 }}>
										<Stack direction={'column'} width={'100%'}>
											<FormLabel>{'No. of employees'}</FormLabel>
											<NumericFormat
												customInput={TextField}
												decimalScale={0}
												fixedDecimalScale
												thousandSeparator
												value={formik.values.numberofemployees}
												onValueChange={({ value }) => {
													formik.setFieldValue(NO_EMPLOYEES, value);
												}}
												disabled={loading}
												name={NO_EMPLOYEES}
											/>
										</Stack>
									</Grid>
									<Grid size={{ xs: 12, md: 6 }}>
										<Stack direction={'column'} width={'100%'}>
											<FormLabel>{'Annual turnover'}</FormLabel>
											<NumericFormat
												prefix='$'
												decimalScale={0}
												fixedDecimalScale
												thousandSeparator
												allowNegative={false}
												customInput={TextField}
												value={formik.values.annualrevenue}
												onValueChange={({ floatValue }) => {
													formik.setFieldValue(
														ANNUAL_TURNOVER,
														floatValue
													);
												}}
												disabled={loading}
												name={ANNUAL_TURNOVER}
											/>
										</Stack>
									</Grid>
									<ContactFields
										formik={formik}
										loading={loading}
										existingContact={existingContact}
									/>
								</>
							)}

							<Grid size={12}>
								<FormLabel>{'Description of Business Activities'}</FormLabel>
								<TextField
									fullWidth
									name={OTHER_INFO}
									multiline
									rows={4}
									{...formik.getFieldProps(OTHER_INFO)}
									disabled={loading}
								/>
								<FormHelperText>
										Provide a brief description of the activities this business conducts to give context for the deal.
								</FormHelperText>
							</Grid>
							<Grid size={{ xs: 12, md: 4 }}>
								<FormLabel>{'Renewal date'}</FormLabel>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DatePicker
										name={RENEWAL_DATE}
										sx={{ width: '100%' }}
										disabled={formik.isSubmitting}
										format='dd/MM/yyyy'
										value={formik.values.renewalDate}
										onChange={(value) => {
											// 						const newDate =
											//   !isValid(value) || isAfter(minDate, value)
											//   	? minDate
											//   	: value;
											formik.setFieldValue(RENEWAL_DATE, value);
										}}
									/>
								</LocalizationProvider>
								<FormHelperText>
										Set the renewal date for this business deal to ensure timely follow-ups.
								</FormHelperText>
							</Grid>
							<Grid size={{ xs: 12, md: 4 }}>
								<FormLabel>{'Priority'}</FormLabel>
								<Select
									name={PRIORITY}
									value={formik.values.priority}
									disabled={formik.isSubmitting}
									onChange={(e) =>
										formik.setFieldValue(PRIORITY, e.target.value)
									}
									fullWidth={true}
								>
									{taskPriorities.map((value) => {
										return (
											<MenuItem
												value={value}
												key={`priority-selection-${value}`}
											>
												{value}
											</MenuItem>
										);
									})}
								</Select>
								<FormHelperText>
										Indicate the priority level of this deal to help focus on the most urgent tasks.
								</FormHelperText>
							</Grid>
							<Grid size={{ xs: 12, md: 4 }}>
								<FormControl fullWidth>
									<FormLabel>{'Current Premium'}</FormLabel>
									<NumericFormat
										customInput={TextField}
										prefix='$'
										decimalScale={0}
										fixedDecimalScale
										thousandSeparator
										allowNegative={false}
										value={formik.values.adviserFee}
										onValueChange={({ floatValue }) => {
											formik.setFieldValue(ADVISER_FEE, floatValue);
										}}
										disabled={loading}
										name={ADVISER_FEE}
									/>
								</FormControl>
								<FormHelperText>
										Enter the current premium amount for this business deal.
								</FormHelperText>
							</Grid>
							<Grid size={12}>
								<FileUpload
									files={formik.values.files}
									setFiles={(files) =>
										formik.handleChange({
											target: {
												name: FILES,
												value: files,
											},
										})
									}
									loading={loading}
								/>
							</Grid>
							<Grid size={12}>
								<AdditionalScopeFields
									formik={formik}
									loading={loading}
									disabled={
										formik.isSubmitting ||
											isExistingClient ||
											isExistingContact ||
											!canCreate
									}
								/>
							</Grid>
							{formik.values.errorMessage.length > 0 && (
								<FormHelperText sx={{ padding: '1em' }} error>
									{formik.values.errorMessage}
								</FormHelperText>
							)}
							{formik.values.successMessage.length > 0 && (
								<FormHelperText sx={{ color: 'green', padding: '1em' }}>
									{formik.values.successMessage}
								</FormHelperText>
							)}
						</Grid>
					)}
				</CardContent>
				<Divider />
				<CardActions
					sx={{
						justifyContent: 'center',
						p: 2,
					}}
				>
					<Button
						color='primary'
						type='submit'
						variant='contained'
						disabled={isLoading || isUninitialized || formLoading}
					>
						{formik.isSubmitting ? (
							<CircularProgress size='2em' sx={{ color: '#ffffff' }} />
						) : (
							'Submit'
						)}
					</Button>
				</CardActions>
			</form>
		</Container>
	);
};

export default CreateNewBusinessForm;
