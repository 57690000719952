/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	FormHelperText,
	CircularProgress,
	FormLabel,
	Container,
	Stack,
	Typography,
	Card,
	FormControl,
	MenuItem,
	Select,
	Skeleton,
	Collapse,
	Fade,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { TransitionGroup } from 'react-transition-group';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useFormik } from 'formik';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';

import { validationSchema } from './validationSchema';
import {
	useCreateRenewalMutation,
	useGetDealPoliciesQuery,
	useGetDealPropertiesQuery,
	useGetDealRowByPolicyIdQuery,
	useGetDealRowQuery,
	useGetDealsListByIdsQuery,
} from '@/features/deals/dealsApi';
import { InstantClientSelect } from '@/components/selects/ClientSelect';
import { PolicySelect } from '@/components/selects/PolicySelect';
import { PolicyTransactionSelect } from '@/components/selects/PolicyTransactionSelect';
import FileUpload from '@/components/FileUpload';
import { PrioritySelect } from '@/components/selects/PrioritySelect';
import { PageHeader } from '@/components/layouts/PageHeader';
import { showToast } from '@/features/toast/toastSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { reset } from '@/features/select/clientSelectSlice';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { OwnerSelect } from '@/components/selects/OwnerSelect';
import {
	ADDITIONAL_SPECIALIST_PRODUCTS,
	ADVISER_FEE,
	CLIENT_CATEGORY,
	CLIENT_ID,
	CLIENT_TYPE,
	COMMERCIAL,
	COMPLETED_BY,
	DEAL_NAME,
	FULL_NEEDS_ANALYSIS,
	GOALS_OF_COVER,
	INDUSTRY,
	NEEDS_ANALYSIS,
	OTHER_INFO,
	PRIORITY,
	RENEWAL_DATE,
	SCOPE_OF_ADVICE,
	YEAR_FOLDER,
} from '../CreateNewBusinessForm/validationSchema';
import { paths } from '@/app/routes';
import {
	currentYear,
	formatDate,
	getPipelineIcon,
	nextYear,
	taskPriorities,
	yearFolderOptions,
} from '@/utils/constants';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { useGetClientPropertiesQuery } from '@/features/clients/clientsApi';
import { AdditionalScopeFields } from '../CreateNewBusinessForm/AdditionalScopeFields';
import { NumericFormat } from 'react-number-format';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useGetDealNoteSummariesQuery } from '@/features/factFind/factFindApi';
import { InstantDealSelect } from '@/components/selects/DealSelect';

const PolicyInfoRow = ({
	id,
	description,
	typeDescription,
	toDate,
	classOfRisk,
	policyNumber,
	loading,
}) => {
	return (
		<Stack width='100%'>
			{loading ? (
				<Skeleton variant='text' width={'25%'} />
			) : (
				<Typography sx={{ fontWeight: 500 }}>{description}</Typography>
			)}
			<Stack direction='row' justifyContent={'space-between'} width='100%'>
				{loading ? (
					<Skeleton variant='text' width={'40%'} />
				) : (
					<Typography>{`${typeDescription}, ${classOfRisk}, ${policyNumber}`}</Typography>
				)}
				{loading ? (
					<Skeleton variant='text' width={'8%'} />
				) : (
					toDate && (
						<Typography variant='task_updated'>
							{'Renewal date: '}
							{formatDate(new Date(toDate))}
						</Typography>
					)
				)}
			</Stack>
		</Stack>
	);
};

export const CreateRenewalForm = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const account = useSelector((state) => state.msalAccount.account);

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'renewals' });
	const showNewRenewalsForm = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	useEffect(
		() => console.log('Show new renewals form', showNewRenewalsForm),
		[showNewRenewalsForm]
	);

	const existingClientId = useMemo(() => location?.state?.clientId, [location?.state?.clientId]);

	const clientQuery = useGetClientPropertiesQuery(existingClientId, {
		skip: !existingClientId,
	});

	const existingClient = useMemo(() => {
		const clientProps = clientQuery.data?.properties
			? {
				id: clientQuery.data?.id ?? clientQuery.data?.properties?.hs_object_id,
				...clientQuery.data?.properties,
			  }
			: null;
		console.log('Client query', clientProps);
		return clientProps ?? location?.state?.client ?? {};
	}, [location?.state?.client, clientQuery.data?.properties]);

	// const existingDeal = useMemo(() => location?.state?.deal ?? {}, [location?.state?.deal]);
	const existingDealIds = useMemo(
		() => location?.state?.dealIds ?? [],
		[location?.state?.dealIds]
	);
	const existingPolicy = useMemo(() => location?.state?.policy ?? {}, [location?.state?.policy]);

	const mapPolicies = (policy) => ({
		id: policy.Id ?? 0,
		description: policy.Description ?? '',
		typeDescription: policy.TypeDescription ?? '',
		toDate: policy.ToDate,
		classOfRisk: policy.ClassOfRisk ?? '',
		policyNumber: policy.PolicyNumber ?? 'TBA',
	});

	const existingPolicies = useMemo(() => {
		const policy = location?.state?.policy ?? {};
		const array = location?.state?.policies ?? [];
		const policies = (
			array.length > 0 ? array : Object.keys(policy).length > 0 ? [policy] : []
		).map(mapPolicies);
		return Array.from(policies);
	}, [location?.state?.policies, location?.state?.policy]);

	const isExistingClient = useMemo(
		() => Object.keys(existingClient).length > 0 || existingClientId,
		[existingClient]
	);
	const isExistingDeal = useMemo(() => existingDealIds.length > 0, [existingDealIds]);

	const [formLoading, setFormLoading] = useState(false);

	const email = account?.username;
	const {
		data: userDetails,
		isLoading,
		isUninitialized,
		isError,
	} = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
	const hubspotId = userDetails?.hubspotId;

	const initialValues = {
		policy: null,
		policyId: -1,
		policies: [],
		// transaction: existingTransaction,
		// policyTransactionId: existingTransaction?.Id,
		files: [],
		notes: '', //summaries[0]?.content?.[0]?.text?.value ?? '',
		clientName: '',
		clientOwner: hubspotId,
		successMessage: '',
		errorMessage: '',
		[YEAR_FOLDER]: `${currentYear}-${nextYear}`,
		[DEAL_NAME]: '',
		dealIds: [],
		[OTHER_INFO]: '', //dealRowQuery.data?.description ?? '',
		[PRIORITY]: '',
		[RENEWAL_DATE]: null,
		[CLIENT_ID]: '',
		[ADVISER_FEE]: '', //dealRowQuery.data?.[ADVISER_FEE],
		[INDUSTRY]: '', //dealRowQuery.data?.[INDUSTRY],
		[COMPLETED_BY]: null,
		[GOALS_OF_COVER]: [], //dealRowQuery.data?.[GOALS_OF_COVER] ?? [],
		[CLIENT_CATEGORY]: [], //dealRowQuery.data?.[CLIENT_CATEGORY] ?? [],
		[SCOPE_OF_ADVICE]: [], //dealRowQuery.data?.[SCOPE_OF_ADVICE] ?? [],
		[NEEDS_ANALYSIS]: [], //dealRowQuery.data?.[NEEDS_ANALYSIS] ?? [],
		[CLIENT_TYPE]: COMMERCIAL, //dealRowQuery.data?.[CLIENT_TYPE] ?? COMMERCIAL,
		[ADDITIONAL_SPECIALIST_PRODUCTS]: '',
		//dealRowQuery.data?.[ADDITIONAL_SPECIALIST_PRODUCTS] ?? '',
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			setFormLoading(true);
			console.log('📋 ~ Formik values', values);
			const createError = (message) => {
				setFieldValue('errorMessage', message ?? 'Error creating renewal');
				setFieldValue('successMessage', '');
				setSubmitting(false);
				setFormLoading(false);
			};
			const res = await createNewRenewal();
			if (res?.error) {
				console.log('🙅 ~ Error creating endorsement', res?.error);
				createError(res?.error?.message);
				return;
			}
			const response = await createRenewalFolders(res.dealId, res.noteId);
			if (response?.error) {
				createError(response?.error?.message);
				dispatch(
					showToast({
						message: response?.error?.message ?? 'Renewal created',
						action: {
							path: `${paths.renewals}/${res.dealId}`,
							label: 'GO TO RENEWAL',
						},
						autohide: false,
					})
				);
				return;
			}
			console.log('🚀 ~ Successfully created new Renewal', res);
			dispatch(
				showToast({
					message: 'New renewal created!',
					action: {
						path: `${paths.renewals}/${res.dealId}`,
						label: 'GO TO RENEWAL',
					},
					autohide: false,
				})
			);
			window.scrollTo(0, 0);
			window.history.replaceState({}, '');
			resetForm();
			dispatch(reset());
			setFieldValue('successMessage', 'Successfully created Renewal');
			setFieldValue('errorMessage', '');
			setSubmitting(false);
			setFormLoading(false);
		},
	});

	const dealsPropertiesQuery = useGetDealsListByIdsQuery(
		{
			clientId: formik.values[CLIENT_ID],
			dealIds: formik.values.dealIds,
			includePolicies: true,
		},
		{
			skip:
				(formik.values[CLIENT_ID] ?? '').length === 0 ||
				(formik.values.dealIds ?? []).length === 0,
		}
	);

	const dealProperties = useMemo(() => {
		return formik.values.dealIds.length === 0 || !dealsPropertiesQuery.data
			? []
			: dealsPropertiesQuery.data;
	}, [dealsPropertiesQuery.data, formik.values.dealIds]);

	const dealRowQuery = useGetDealRowQuery(formik.values.dealIds[0], {
		skip: !showNewRenewalsForm || formik.values.dealIds.length === 0,
	});
	const dealRow = useMemo(
		() => (formik.values.dealIds.length === 0 ? {} : dealRowQuery.data),
		[dealRowQuery.data, formik.values.dealIds]
	);

	const noteSummaryQuery = useGetDealNoteSummariesQuery(
		{ dealId: formik.values.dealIds[0] },
		{
			skip: !showNewRenewalsForm || formik.values.dealIds.length === 0,
		}
	);

	const summaries = useMemo(
		() => (formik.values.dealIds.length === 0 ? {} : noteSummaryQuery.data?.summary ?? []),
		[noteSummaryQuery.data?.summary, formik.values.dealIds]
	);

	useEffect(() => {
		formik.setFieldValue('dealIds', existingDealIds ?? []);
	}, [existingDealIds]);

	useEffect(() => {
		formik.setFieldValue('notes', summaries[0]?.content?.[0]?.text?.value ?? '');
	}, [summaries]);

	useEffect(() => {
		formik.setFieldValue(OTHER_INFO, dealRow?.description ?? '');
		formik.setFieldValue(ADVISER_FEE, dealRow?.[ADVISER_FEE]);
		formik.setFieldValue(INDUSTRY, dealRow?.[INDUSTRY]);
		formik.setFieldValue(GOALS_OF_COVER, dealRow?.[GOALS_OF_COVER] ?? []);
		formik.setFieldValue(CLIENT_CATEGORY, dealRow?.[CLIENT_CATEGORY] ?? []);
		formik.setFieldValue(SCOPE_OF_ADVICE, dealRow?.[SCOPE_OF_ADVICE] ?? []);
		formik.setFieldValue(NEEDS_ANALYSIS, dealRow?.[NEEDS_ANALYSIS] ?? []);
		formik.setFieldValue(CLIENT_TYPE, (dealRow?.[CLIENT_TYPE] ?? '').length > 0 ? dealRow?.[CLIENT_TYPE] : COMMERCIAL);
		formik.setFieldValue(
			ADDITIONAL_SPECIALIST_PRODUCTS,
			dealRow?.[ADDITIONAL_SPECIALIST_PRODUCTS] ?? ''
		);
	}, [dealRow]);

	useEffect(() => {
		const renewalDate = existingPolicies
			.map((p) => (p.toDate ? new Date(p.toDate) : null))
			.sort()[0];

		formik.setFieldValue('policy', existingPolicy);
		formik.setFieldValue('policyId', existingPolicy?.Id ?? -1);
		formik.setFieldValue('policies', Array.from(existingPolicies ?? []));
		formik.setFieldValue(
			RENEWAL_DATE,
			renewalDate ?? (existingPolicy?.ToDate ? new Date(existingPolicy?.ToDate) : null)
		);
	}, [existingPolicy, existingPolicies]);

	useEffect(() => {
		formik.setFieldValue('clientName', existingClient?.name ?? '');
		formik.setFieldValue(
			'clientOwner',
			existingClient?.hubspot_owner_id ?? existingClient?.ownerId ?? hubspotId
		);
		formik.setFieldValue(CLIENT_ID, existingClient?.id ?? existingClient?.hs_object_id ?? '');
		formik.setFieldValue(INDUSTRY, existingClient?.industry ?? '');
	}, [existingClient, hubspotId]);

	// useEffect(
	// 	() => console.log('DEAL PROPERTIES', dealsPropertiesQuery.data),
	// 	[dealsPropertiesQuery.data]
	// );

	const { data: ownerData } = useGetOwnerQuery(
		{ ownerId: formik.values.clientOwner },
		{ skip: !formik.values.clientOwner }
	);

	const { data: ownerDetails } = useGetUserDetailsQuery(
		{ hubspotId: ownerData?.id },
		{ skip: !ownerData }
	);

	useEffect(() => {
		const mappedPolicies = dealProperties.flatMap((deal) =>
			deal.policies.map(mapPolicies).reduce((acc, pol) => {
				acc.push({ ...pol, deal });
				return acc;
			}, [])
		);
		console.log('MAPPED POLICIES', mappedPolicies);
		formik.setFieldValue('policies', mappedPolicies);
		const renewalDate = mappedPolicies?.[0]?.toDate ?? mappedPolicies?.[0]?.ToDate;
		formik.setFieldValue(RENEWAL_DATE, renewalDate ? new Date(renewalDate) : null);
	}, [dealProperties]);

	const { setUpDealFilesAndFolders } = useClientStorageHooks(ownerDetails ?? userDetails);

	const [createRenewal] = useCreateRenewalMutation();

	const createRenewalFolders = async (dealId, noteId) => {
		try {
			const { documentId } = await setUpDealFilesAndFolders(
				dealId,
				noteId,
				formik.values.clientName,
				formik.values.dealName,
				process.env.REACT_APP_PIPELINE_RENEWALS,
				formik.values.files,
				null,
				formik.values.yearFolder
			);
			console.log('Here is the doc Id:', documentId);
		} catch (error) {
			console.log('🚀 ~ Error creating renewal folders', error);
			return {
				error: {
					message: `Renewal ${dealId} created, but there was an error creating renewal folder or uploading files`,
				},
			};
		}
	};

	const createNewRenewal = async () => {
		const body = {
			hubspotId: formik.values.clientOwner,
			dealName: formik.values.dealName,
			notes: formik.values.notes,
			clientId: formik.values.clientId,
			// ...((formik.values.policyId ?? 0) > 0 && { policyId: formik.values.policyId }),

			policyIds: showNewRenewalsForm
				? formik.values.policies.map((p) => p.id ?? p.Id)
				: (formik.values.policyId ?? 0) > 0
					? [formik.values.policyId]
					: [],
			// policyTransactionId: formik.values.policyTransactionId,
			[PRIORITY]: formik.values.priority.length > 0 ? formik.values.priority : null,
			[COMPLETED_BY]: hubspotId,
			[YEAR_FOLDER]: null,
			...(formik.values.dealIds.length > 0 && { previousDealIds: formik.values.dealIds }),
			description: formik.values[OTHER_INFO],
			adviserFee: formik.values[ADVISER_FEE],
			clientGoals: formik.values[GOALS_OF_COVER],
			clientCategory: formik.values.clientCategory,
			clientType: formik.values.clientType,
			needsAnalysis: formik.values[NEEDS_ANALYSIS],
			additionalProducts: formik.values.additionalSpecialistProducts,
			riskClasses: formik.values[SCOPE_OF_ADVICE],
			[FULL_NEEDS_ANALYSIS]: formik.values[FULL_NEEDS_ANALYSIS] ?? false,
		};

		if (formik.values.renewalDate) {
			const renewalDate = new Date(formik.values.renewalDate);
			const timezoneOffset = renewalDate.getTimezoneOffset();
			renewalDate.setMinutes(renewalDate.getMinutes() - timezoneOffset);
			const timestamp = renewalDate.setUTCHours(0, 0, 0, 0);
			body.renewalDate = timestamp;
		}

		return await createRenewal(body)
			.unwrap()
			.catch((err) => {
				console.log('🚀 ~ Error creating renewal', err);
				return { error: { message: 'Error creating renewal' } };
			});
	};

	useEffect(() => {
		formik.setFieldValue(
			'dealName',
			(
				`${
					showNewRenewalsForm
						? `${dealRowQuery.data?.dealName ?? ''}`
						: formik.values.policy?.Description ?? ''
				} Renewal` ?? ''
			)
				.trim()
				.replace('.', '')
				.replace('/', '-')
		);
	}, [formik.values.policy, dealRowQuery.data, showNewRenewalsForm]);

	const { getError, getErrorMessage } = useFormikHelper(formik);

	const loading = formik.isSubmitting || formLoading;

	useEffect(() => {
		console.log('formik values: ', formik);
	}, [formik]);

	return (
		<Container sx={{ pt: 3 }}>
			<Button
				variant='text'
				onClick={() => navigate(paths.renewals)}
				sx={{ color: 'black', fontWeight: 'bold', mb: '1em' }}
				startIcon={<KeyboardBackspaceOutlinedIcon />}
			>
				{'Back to Renewals'}
			</Button>
			<form onSubmit={formik.handleSubmit}>
				<CardHeader
					disableTypography
					title={
						<PageHeader
							title={'Add Renewal'}
							icon={getPipelineIcon(process.env.REACT_APP_PIPELINE_RENEWALS)}
						/>
					}
				/>
				<CardContent>
					{/* <Typography variant="body1" paragraph>
              View our user guide on the steps to add a renewal{' '}
							<Link>here</Link>
						</Typography> */}
					{isLoading || isUninitialized ? (
						<CircularProgress />
					) : (
						<Grid container spacing={4}>
							{showNewRenewalsForm && (
								<>
									<Grid size={8}>
										<FormLabel required>{'Deal name'}</FormLabel>
										<TextField
											fullWidth
											{...formik.getFieldProps(DEAL_NAME)}
											error={getError(DEAL_NAME)}
											helperText={getErrorMessage(DEAL_NAME)}
											disabled={loading || formik.values.dealIds.length === 0}
											// disabled={true}
											// required
											name={DEAL_NAME}
										/>
										<FormHelperText>
											Enter a clear and friendly name for this business deal
											to help you easily identify it later.
										</FormHelperText>
									</Grid>
									<Grid size={4}>
										<FormLabel>{'Year folder'}</FormLabel>
										<Select
											name={YEAR_FOLDER}
											value={formik.values.yearFolder}
											disabled={formik.isSubmitting}
											onChange={(e) =>
												formik.setFieldValue(YEAR_FOLDER, e.target.value)
											}
											fullWidth={true}
										>
											{yearFolderOptions.map((value) => {
												return (
													<MenuItem
														value={value}
														key={`year-folder-selection-${value}`}
													>
														{value}
													</MenuItem>
												);
											})}
										</Select>
										<FormHelperText>
											Specify the year this business deal applies to, keeping
											your records organized.
										</FormHelperText>
									</Grid>
								</>
							)}
							<Grid size={'grow'}>
								<FormLabel required>
									{isExistingClient ? 'Client name' : 'Search client name'}
								</FormLabel>
								<InstantClientSelect
									clientId={formik.values.clientId}
									clientName={formik.values.clientName}
									filterProspects={true}
									setClient={(newClient) => {
										console.log('Here is the selected client: ', newClient);
										if (newClient) {
											formik.setValues({
												...formik.values,
												clientId: newClient.id,
												clientName: newClient.name,
												clientOwner: newClient.ownerId,
											});
										} else {
											formik.setValues({
												...formik.values,
												clientId: null,
												// dealId: null,
												dealIds: [],
												dealName: '',
												clientName: '',
												clientOwner: hubspotId,
												policy: existingPolicy ?? null,
												policyId: existingPolicy?.Id ?? -1,
												policies: Array.from(existingPolicies ?? []),
											});
										}
									}}
									disabled={loading || isExistingClient}
								/>
								<FormHelperText>
								Search for and select the client associated with this business deal.
								</FormHelperText>
							</Grid>
							<Grid size={4}>
								<FormLabel>{'Assign to'}</FormLabel>
								<OwnerSelect
									initialId={formik.values.clientOwner}
									disabled={formik.isSubmitting}
									onChange={(value) => {
										formik.setFieldValue('clientOwner', value);
									}}
								/>
								<FormHelperText>
									Select the broker responsible for managing this business deal.
								</FormHelperText>
							</Grid>
							{showNewRenewalsForm && (
								<>
									<Grid size={12}>
										<FormLabel required>{'Associated deals'}</FormLabel>
										<InstantDealSelect
											disabled={
												formik.isSubmitting || !formik.values.clientId
												|| isExistingDeal
											}
											clientId={formik.values.clientId}
											dealId={formik.values.dealIds}
											multiple={true}
											showRenewable={true}
											displayClaims={false}
											disabledDealIds={existingDealIds}
											setDeal={(newDeals) => {
												console.log('NEW DEALS', newDeals);
												// Update existingDeal state
												formik.setFieldValue(
													'dealIds',
													Array.from(
														new Set(
															existingDealIds.concat(
																newDeals.map((d) => d.id)
															)
														)
													)
												);
											}}
											autoPopulate={true}
											hideInput={isExistingDeal}
										/>
										{formik.values.dealIds.length > 1 && (
											<FormHelperText>
												Please note that deal data will only be pulled from
												first deal selected.
											</FormHelperText>
										)}
									</Grid>
									{/* <Grid size={3}>
										<FormLabel>{'Year folder'}</FormLabel>
										<Select
											name={YEAR_FOLDER}
											value={formik.values.yearFolder}
											disabled={formik.isSubmitting}
											onChange={(e) =>
												formik.setFieldValue(YEAR_FOLDER, e.target.value)
											}
											fullWidth={true}
										>
											{yearFolderOptions.map((value) => {
												return (
													<MenuItem
														value={value}
														key={`year-folder-selection-${value}`}
													>
														{value}
													</MenuItem>
												);
											})}
										</Select>
									</Grid> */}
								</>
							)}
							{showNewRenewalsForm ? (
								<Grid size={12}>
									<FormLabel sx={{ display: 'flex', pb: '0.5em' }}>
										Policies
									</FormLabel>
									<TransitionGroup>
										{dealsPropertiesQuery.isLoading ||
										dealsPropertiesQuery.isFetching || (existingDealIds.length > 0 && dealsPropertiesQuery.isUninitialized) ? (
												formik.values.dealIds.map((dealId) => (
													<Collapse
														sx={{ marginBottom: '1em' }}
														key={`loading-policy-${dealId}`}
													>
														<PolicyInfoRow loading={true} />
													</Collapse>
												))
											) : formik?.values?.policies?.length > 0 ? (
												formik?.values?.policies.map((policy, i) => (
													<Collapse
														key={`policy-info-${i}-${policy.id}`}
														sx={{ marginBottom: '1em' }}
													>
														<PolicyInfoRow {...policy} />
													</Collapse>
												))
											) : (
												<Collapse sx={{ marginBottom: '1em' }}>
													<Typography>
														{formik.values.dealIds.length === 0
															? 'Please select a deal to view policies to be renewed'
															: 'No policies found'}
													</Typography>
												</Collapse>
											)}
									</TransitionGroup>
								</Grid>
							) : (
								<Grid size={12}>
									<FormLabel required sx={{ display: 'flex' }}>
										{'Policy'}
									</FormLabel>
									<PolicySelect
										clientId={formik.values.clientId}
										policy={formik.values.policy}
										setPolicy={(newPolicy) => {
											console.log('NEW POLICY', newPolicy);
											formik.setValues({
												...formik.values,
												policy: newPolicy,
												policyId: newPolicy?.Id ?? -1,
												renewalDate: newPolicy?.ToDate
													? new Date(newPolicy?.ToDate)
													: null,
											});
										}}
										showRenewalDate={true}
										disabled={Object.keys(existingPolicy).length > 0 || loading}
									/>
									<FormHelperText error={getError('policyId')}>
										{getErrorMessage('policyId')}
									</FormHelperText>
								</Grid>
							)}
							{/* <Grid size={12}>
									<FormLabel required sx={{ display: 'flex' }}>
										{'Policy Transaction'}
									</FormLabel>
									<PolicyTransactionSelect
										policyId={formik.values.policyId}
										policyTransaction={formik.values.transaction}
										setPolicyTrans={(newPolicy) => {
											formik.setFieldValue(
												'policyTransactionId',
												newPolicy?.Id
											);
											formik.setFieldValue('transaction', newPolicy);
										}}
										disabled={
											Object.keys(existingTransaction ?? {}).length > 0 ||
											loading
										}
									/>
									<FormHelperText error={getError('policyTransactionId')}>
										{getErrorMessage('policyTransactionId')}
									</FormHelperText>
								</Grid> */}
							{showNewRenewalsForm ? (
								<>
									<Grid size={12}>
										<FormLabel>
											{'Description of Business Activities'}
										</FormLabel>
										<TextField
											fullWidth
											name={OTHER_INFO}
											multiline
											rows={4}
											{...formik.getFieldProps(OTHER_INFO)}
											disabled={loading}
										/>
										<FormHelperText>
											Provide a brief description of the activities this
											business conducts to give context for the deal.
										</FormHelperText>
									</Grid>
									<Grid size={{ xs: 12, md: 4 }}>
										<FormLabel>{'Renewal date'}</FormLabel>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DatePicker
												name={RENEWAL_DATE}
												sx={{ width: '100%' }}
												disabled={formik.isSubmitting}
												format='dd/MM/yyyy'
												value={formik.values.renewalDate}
												onChange={(value) => {
													// 						const newDate =
													//   !isValid(value) || isAfter(minDate, value)
													//   	? minDate
													//   	: value;
													formik.setFieldValue(RENEWAL_DATE, value);
												}}
											/>
										</LocalizationProvider>
										<FormHelperText>
											Set the renewal date for this business deal to ensure
											timely follow-ups.
										</FormHelperText>
									</Grid>
									<Grid size={{ xs: 12, md: 4 }}>
										<FormLabel>{'Priority'}</FormLabel>
										<Select
											name={PRIORITY}
											value={formik.values.priority}
											disabled={formik.isSubmitting}
											onChange={(e) =>
												formik.setFieldValue(PRIORITY, e.target.value)
											}
											fullWidth={true}
										>
											{taskPriorities.map((value) => {
												return (
													<MenuItem
														value={value}
														key={`priority-selection-${value}`}
													>
														{value}
													</MenuItem>
												);
											})}
										</Select>
										<FormHelperText>
											Indicate the priority level of this deal to help focus
											on the most urgent tasks.
										</FormHelperText>
									</Grid>
									<Grid size={{ xs: 12, md: 4 }}>
										<FormControl fullWidth>
											<FormLabel>{'Current Premium'}</FormLabel>
											<NumericFormat
												customInput={TextField}
												prefix='$'
												decimalScale={0}
												fixedDecimalScale
												thousandSeparator
												allowNegative={false}
												value={formik.values.adviserFee}
												onValueChange={({ floatValue }) => {
													formik.setFieldValue(ADVISER_FEE, floatValue);
												}}
												disabled={loading}
												name={ADVISER_FEE}
											/>
										</FormControl>
										<FormHelperText>
											Enter the current premium amount for this business deal.
										</FormHelperText>
									</Grid>
								</>
							) : (
								<Grid size={12}>
									<PrioritySelect
										disabled={formik.isSubmitting}
										priority={formik.values.priority}
										setPriority={(value) =>
											formik.setFieldValue('priority', value)
										}
									/>
									<FormHelperText>
										Indicate the priority level of this deal to help focus on
										the most urgent tasks.
									</FormHelperText>
								</Grid>
							)}
							<Grid size={12}>
								<FormLabel>{'Notes'}</FormLabel>
								<TextField
									fullWidth
									id='notes'
									multiline
									rows={4}
									{...formik.getFieldProps('notes')}
									disabled={loading}
									error={getError('notes')}
									helperText={getErrorMessage('notes')}
								/>
								<FormHelperText>
									Include any additional details or context that might be helpful
									for future reference.
								</FormHelperText>
							</Grid>
							<Grid size={12}>
								<FileUpload
									files={formik.values.files}
									setFiles={(files) =>
										formik.handleChange({
											target: {
												name: 'files',
												value: files,
											},
										})
									}
									loading={loading}
								/>
								<FormHelperText>
									Upload any relevant files for this deal—these will be securely
									attached for easy access later.
								</FormHelperText>
							</Grid>
							{showNewRenewalsForm && (
								<Grid size={12}>
									<AdditionalScopeFields
										formik={formik}
										loading={loading}
										disabled={
											formik.isSubmitting ||
											formik.values.dealIds.length === 0
										}
									/>
								</Grid>
							)}
							{formik.values.errorMessage.length > 0 && (
								<FormHelperText sx={{ padding: '1em' }} error>
									{formik.values.errorMessage}
								</FormHelperText>
							)}
							{formik.values.successMessage.length > 0 && (
								<FormHelperText sx={{ color: 'green', padding: '1em' }}>
									{formik.values.successMessage}
								</FormHelperText>
							)}
						</Grid>
					)}
				</CardContent>
				{/* <pre>{JSON.stringify(formik.values,null,2)}</pre> */}
				<Divider />
				<CardActions
					sx={{
						justifyContent: 'center',
						p: 2,
					}}
				>
					<Button
						color='primary'
						type='submit'
						variant='contained'
						disabled={isLoading || isUninitialized || formLoading}
					>
						{formik.isSubmitting ? (
							<CircularProgress size='2em' sx={{ color: '#ffffff' }} />
						) : (
							'Submit'
						)}
					</Button>
				</CardActions>
			</form>
		</Container>
	);
};
