import React, { useMemo } from 'react';
import { 
	TableCell, 
	TableRow, 
} from '@mui/material';
import { ClientNameAndAvatar } from '@/components/table/ClientNameAndAvatar';
import { useNavigate } from 'react-router';
import { paths } from '@/app/routes';
import { getNameInitials } from '@/utils/constants';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

export const ClientRow = ({hit: client}) => {
	const navigate = useNavigate();

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'prospects' });
	const showProspects = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	return (
		<TableRow 
			onClick={() => navigate(`${paths.clients}/${client.id}`)}
			hover 
			key={`client-table-row-${client.id}`}
			style={{ cursor: 'pointer' }}
		>
			<TableCell width={'35%'}>
				<ClientNameAndAvatar 
					title={client.name} 
					subtitle={client.contactName} 
					initials={getNameInitials(client.name)}
				/>
			</TableCell>
			{showProspects && (
				<TableCell>
					{client?.isProspect ? 'Prospect' : 'Client'}
				</TableCell>
			)}
			<TableCell width={'20%'}>{client.contactName}</TableCell>
			<TableCell width={'20%'}>{client.contactEmail}</TableCell>
			<TableCell width={'25%'} align={'right'}>{client.contactPhone}</TableCell>
		</TableRow>
	);
};
