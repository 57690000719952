import { isAfter } from 'date-fns';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	title: Yup.string().required('Title is required.'),
	dueDate: Yup.date().required('Due date is required.'),
	priority: Yup.string().required('Priority is required.'),
	notes: Yup.string(), //.required('Task info is required.'),
	taskType: Yup.string().required('Task type is required.'),
	address: Yup.string()
		.test(
			'is-address-required',
			'Address is required for meetings',
			function (value) {
				const { taskType, isOnlineMeeting } = this.parent;
				if (taskType === 'MEETING' && !isOnlineMeeting) {
					return (value ?? '').length > 0;
				}
				return true;
			}
		)
		.nullable(),
	startTime: Yup.date()
		.nullable()
		.when('taskType', {
			is: (taskType) => taskType === 'MEETING' || taskType === 'CALL',
			then: (schema) => schema.required('Start time is required.'),
			otherwise: (schema) => schema.nullable(),
		}),
	endTime: Yup.date()
		.nullable()
		.when('taskType', {
			is: (taskType) => taskType === 'MEETING' || taskType === 'CALL',
			then: (schema) =>
				schema
					.required('End time is required.')
					.test(
						'is-greater',
						'End time must be later than start time',
						function (value) {
							const { startTime } = this.parent;
							return !startTime || !value || isAfter(value, startTime);
						}
					),
			otherwise: (schema) => schema.nullable(),
		}),
	ownerId: Yup.string().required('Assignee is required.'),
});