/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo, useEffect, useCallback } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
	BUSINESS_INTERRUPTION,
	DOMESTIC,
	EMPLOYERS_LIABILITY,
	GENERAL_LIABILITY,
	MANAGEMENT_LIABILITY,
	MATERIAL_DAMAGE,
	SCOPE_OF_ADVICE,
	SCOPE_OF_ADVICE_LABELS_DOMESTIC,
	STATUTORY_LIABILITY,
	SCOPE_OF_ADVICE_LABELS_COMMERCIAL,
	COMMERCIAL,
	NEEDS_ANALYSIS,
	CLIENT_QUOTED,
	FULL_NEEDS_ANALYSIS,
	CLIENT_TYPE,
} from '@/components/forms/CreateNewBusinessForm/validationSchema';
import { determineContext } from '@/hooks/determineContext';
import { ClientCheckbox } from '@/components/buttons/ClientCheckbox';
import { useUpdateDealRowMutation } from '@/features/deals/dealsApi';

export const RiskClasses = ({ formik }) => {
	//LABELS FOR SCOPE HAVE BEEN CHANGED NEED TO ADJUST WHEN COMES TO DEAL VIEW
	const { dealRow: contextDealRow } = determineContext();
	const dealRow = Object.keys(contextDealRow ?? {}).length > 0 ? contextDealRow : formik?.values;

	const scopeOfAdvice = useMemo(
		() => dealRow?.[SCOPE_OF_ADVICE] ?? [],
		[dealRow?.[SCOPE_OF_ADVICE]]
	);

	const [updateDeal, { isLoading }] = useUpdateDealRowMutation();

	const scopeOfAdviceLabels = useMemo(() => {
		if (dealRow?.[CLIENT_TYPE] === DOMESTIC) {
			return SCOPE_OF_ADVICE_LABELS_DOMESTIC;
		} else {
			return SCOPE_OF_ADVICE_LABELS_COMMERCIAL;
		}
	}, [dealRow?.[CLIENT_TYPE]]);

	const scopeOfAdviceEntries = Object.entries(scopeOfAdviceLabels).filter(([value, label]) => {
		// Include EL and SL if Management Liability is in scopeOfAdvice
		if (scopeOfAdvice.includes(MANAGEMENT_LIABILITY)) {
			if (value === EMPLOYERS_LIABILITY || value === STATUTORY_LIABILITY) {
				return true;
			}
		}

		// Exclude Business Interruption if Material Damage is not included
		if (value === BUSINESS_INTERRUPTION && !scopeOfAdvice.includes(MATERIAL_DAMAGE)) {
			return false;
		}

		// Exclude Statutory Liability and Employers Liability if neither
		// General Liability nor Management Liability is included
		if (
			(value === STATUTORY_LIABILITY || value === EMPLOYERS_LIABILITY) &&
			!scopeOfAdvice.includes(GENERAL_LIABILITY) &&
			!scopeOfAdvice.includes(MANAGEMENT_LIABILITY)
		) {
			return false;
		}

		return value !== FULL_NEEDS_ANALYSIS;
		// return true;
	});

	const fullLabels = Object.keys(scopeOfAdviceLabels).filter(
		(value) => value != FULL_NEEDS_ANALYSIS
	);

	const fullNeedsEntry = Object.entries(scopeOfAdviceLabels).find(
		([value]) => value === FULL_NEEDS_ANALYSIS
	);

	useEffect(() => {
		const updateFields = async () => {
			await onChange(fullLabels);
		};
		if (scopeOfAdvice.includes(FULL_NEEDS_ANALYSIS)) {
			updateFields();
		}
	}, [scopeOfAdvice]);

	const onChange = async (newValues, fullNeeds) => {
		const needsAnalysis = (dealRow?.[NEEDS_ANALYSIS] ?? []).slice();
		const entries = Array.from(new Set(Object.keys(needsAnalysis).concat(newValues)));

		const vals = entries.reduce((acc, key) => {
			if (Object.keys(needsAnalysis).includes(key)) {
				const newVal = needsAnalysis.find((k) => k.key === key);
				if (newVal) {
					acc[key] = newVal;
				}
			} else {
				acc[key] = CLIENT_QUOTED;
			}
			return acc;
		}, {});

		const needsAnalysisArray = Object.entries(vals).map(([key, value]) => ({ key, value }));

		if (formik?.values) {
			formik.setFieldValue(NEEDS_ANALYSIS, needsAnalysisArray);
			formik.setFieldValue(SCOPE_OF_ADVICE, newValues);
			formik.setFieldValue(FULL_NEEDS_ANALYSIS, fullNeeds ?? false);
		} else if (dealRow?.dealId) {
			await updateDeal({
				dealId: dealRow?.dealId,
				docId: dealRow?.id,
				properties: {
					[SCOPE_OF_ADVICE]: newValues,
					[NEEDS_ANALYSIS]: needsAnalysisArray,
					[FULL_NEEDS_ANALYSIS]: fullNeeds ?? false,
				},
			})
				.unwrap()
				.catch((e) => console.log('Error updating deal row', e));
		}
	};

	const fullNeedsChecked = fullLabels.every((value) => scopeOfAdvice.includes(value));

	const indeterminate = !fullNeedsChecked && scopeOfAdvice.length > 0;

	return (
		<>
			{/* <FormControl>
				<FormGroup> */}
			<Grid container>
				<Grid size={{ xs: 12, sm: 6, md: 4 }} key={`risk-classes-${fullNeedsEntry[0]}`}>
					<FormControlLabel
						size='small'
						control={
							<Checkbox
								checked={fullNeedsChecked}
								indeterminate={indeterminate}
								onChange={() => {
									onChange(fullNeedsChecked ? [] : fullLabels, true);
								}}
							/>
						}
						disabled={isLoading}
						label={fullNeedsEntry[1]}
						sx={{
							'& .MuiTypography-root': {
								fontSize: '12px',
							},
							'& .MuiButtonBase-root': {
								fontSize: '18px',
							},
						}}
					/>
				</Grid>
				{scopeOfAdviceEntries.map(([value, label], i) => {
					return (
						<Grid size={{ xs: 12, sm: 6, md: 4 }} key={`risk-classes-${value}-${i}`}>
							<FormControlLabel
								size='small'
								control={
									<ClientCheckbox
										value={value}
										valuesArray={scopeOfAdvice}
										setValues={onChange}
										disabled={isLoading}
									/>
								}
								label={label}
								sx={{
									'& .MuiTypography-root': {
										fontSize: '12px',
									},
									'& .MuiButtonBase-root': {
										fontSize: '18px',
									},
								}}
							/>
						</Grid>
					);
				})}
			</Grid>
			{/* </FormGroup>
			</FormControl> */}
			{/* {scopeOfAdviceEntries.map(([value, label]) => {
				return (
					<Grid size={{ xs: 12, sm: 6 }} key={`risk-classes-${value}`}>
						<FormControlLabel
							size='small'
							control={
								<ClientCheckbox
									value={value}
									valuesArray={scopeOfAdvice}
									setValues={onChange}
									disabled={isLoading}
								/>
							}
							label={label}
							sx={{
								'& .MuiTypography-root': {
									fontSize: '12px',
								},
								'& .MuiButtonBase-root': {
									fontSize: '18px',
								},
							}}
						/>
					</Grid>
				);
			})} */}
		</>
	);
};
