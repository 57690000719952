/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import { ActivityCard } from '@/components/cards/ActivityCard';
import { TaskAccordion } from '@/components/TaskAccordion';
import { NoteAccordion } from '@/components/cards/NoteCard';
import { EmailCard } from '@/components/cards/EmailCard';
import { PolicyLinkedCard } from '@/components/cards/PolicyLinkedCard';
import { determineContext } from '@/hooks/determineContext';
import { Box, Collapse, Typography } from '@mui/material';
import { LoadingActivityCard } from '@/components/cards/LoadingActivityCard';
import { setCount } from '@/features/engagements/engagementsSlice';
import { CreatedActivityCard } from '@/components/cards/CreatedActivityCard';
import { CreatedObjectCard } from '../cards/createdObjectCard';
import { HubspotEmailsCard } from '../cards/HubspotEmailsCard';

export const ActivityTab = () => {
	const dispatch = useDispatch();

	const { tasks, notes, emails, calls, msgFiles, deal, dealRow, client, activities, objectType, sharepoint } = determineContext();

	const { loading, loadingActivities, count } = useSelector((state) => state.engagementsSlice);
	const activitiesCount = Math.max(count.activities ?? 0, 1);
	const isLoading = loading || loadingActivities;
	
	const sortedActivities = useMemo(() => {
		const updatedActivities = (activities ?? []).map((a) => ({
			...a,
			lastUpdated: new Date(a.createdDate._seconds * 1000).getTime(),
		}));
		return ([
			...notes.map((note) => ({ type: 'note', data: note })),
			...tasks
				.filter((task) => task.completed)
				.map((task) => ({ type: 'task', data: task })),
			...emails.map((email) => ({ type: 'email', data: email })),
			...(msgFiles ?? []).map((file) => ({ type: 'file', data: file })),
			...calls.map((call) => ({ type: 'call', data: call })),
			...(updatedActivities.map((activity) => ({
				type: 'activity',
				data: activity,
			}))),			
		]).sort((a, b) => (
			b.data.lastUpdated - a.data.lastUpdated
		));
	}, [msgFiles, notes, tasks, emails, calls, activities]);

	useEffect(() => {
		if (sortedActivities && dispatch) {
			dispatch(setCount({ key: 'activities', count: sortedActivities.length }));
		}
	}, [sortedActivities, dispatch]);
    
	const renderActivityItem = (activity, index) => {
		switch (activity.type) {
		case 'note':
			return (
				<NoteAccordion
					key={`activity-note-${index}`}
					note={activity.data}
				/>
			);
		case 'task':
			return (
				<TaskAccordion
					key={`activity-task-${index}`}
					task={activity.data}
					index={index}
				/>
			);
		case 'email':
			return (
				<HubspotEmailsCard
					key={`activity-${activity.type}-${index}`}
					email={activity.data}
					index={index}
				/>
			)
		case 'call':
			return (
				<ActivityCard
					key={`activity-${activity.type}-${index}`}
					activity={activity.data}
				/>
			);
		case 'file':
			return (
				<EmailCard
					key={`msg-file-${index}`}
					email={activity}
				/>
			);
		case 'activity':
			if (activity.data.activityType.toUpperCase().trim() == 'POLICIES LINKED') {
				return (
					<PolicyLinkedCard
						key={`activity-linked-card-${index}`}
						activity={activity}
					/>
				);
			} 
			else {
				return (
					<CreatedObjectCard key={`object-created-card-${index}`} activity={activity.data} />
				);
			}
			
		default:
			return null;
		}
	};
 
	return (
		<Box px={'0.5em'}>
			{isLoading 
				? 
				[...Array(activitiesCount)].map((_, i) => <LoadingActivityCard key={`loading-activity-${i}`} />) 
				:
				sortedActivities.length > 0 ?
					<TransitionGroup>
						{sortedActivities.map((activity, index) =>
							<Collapse sx={{marginBottom: '5px'}} key={`activity-collapse-${index}`}>{renderActivityItem(activity, index)}</Collapse>
						)}
					</TransitionGroup>
					:
					<Typography textAlign={'center'}>No activity logged yet.</Typography>
			}
		</Box>
	);
};
