/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Skeleton,
	Stack,
	TableCell,
	TableRow,
	Typography,
	Box,
	Button,
	Fade,
	Tooltip,
	Checkbox,
} from '@mui/material';
import { useNavigate } from 'react-router';
import {
	folioBlue,
	formatDate,
	getPipelineIcon,
	getUrlFromPipeline,
	RENEWAL_MAX_DAYS,
} from '@/utils/constants';
import { ClientNameAndAvatar } from '@/components/table/ClientNameAndAvatar';
import { firstTwoCapsOrNumbers } from '@/utils/constants';
import InfoIcon from '@mui/icons-material/Info';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { BlinkingCircle } from '@/components/icons/BlinkingCircle';
import { useGetDealPoliciesQuery } from '@/features/deals/dealsApi';
import { addDays, isAfter } from 'date-fns';
import { paths } from '@/app/routes';
import { useGetClientPropertiesQuery } from '@/features/clients/clientsApi';
import { IS_DEV } from '@/utils/environment';
import { useDispatch, useSelector } from 'react-redux';
import { setSelected } from '@/features/table/dealsTableSlice';

const today = new Date();

export const RenewalsRow = ({ hit: deal }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { selected } = useSelector((state) => state.dealTable);

	const renewalDate = useMemo(
		() => (deal.renewalDate ? new Date(deal.renewalDate) : new Date()),
		[deal.renewalDate]
	);

	const { policies, transactions } = useMemo(() => {
		const policiesRes = new Set(deal.policies.map((r) => r.policy).map(JSON.stringify));
		const transactionsRes = new Set(
			deal.policies.map((r) => r.transaction).map(JSON.stringify)
		);
		return {
			policies: Array.from(policiesRes)
				.map(JSON.parse)
				.filter((p) => Object.keys(p).length > 0), //results.map((r) => r.policy),
			transactions: Array.from(transactionsRes)
				.map(JSON.parse)
				.filter((p) => Object.keys(p).length > 0), //results.map((r) => r.transaction),
		};
	}, [deal.policies]);

	const pipelineQuery = useGetPipelinePropertiesQuery(deal.pipeline, {
		skip: !deal.pipeline,
	});
	const stages = pipelineQuery?.data?.stages ?? {};
	const pipelineName = pipelineQuery?.data?.pipelines?.label ?? '';

	const { active, inactive } = useMemo(() => {
		const pipelineStages =
			pipelineQuery.data?.pipelines?.stages ??
			(pipelineQuery.data?.pipelines?.results ?? [])
				.filter((p) => p.id !== process.env.REACT_APP_PIPELINE_CLAIMS)
				.flatMap((r) => r?.stages) ??
			[];
		return {
			active: pipelineStages
				.filter((stage) => stage.metadata?.isClosed != 'true')
				.map((s) => s.id),
			inactive: pipelineStages
				.filter(
					(stage) =>
						stage.metadata?.probability?.toString() == '1.0' ||
						(IS_DEV &&
							stage.metadata?.isClosed == 'true' &&
							stage.metadata?.probability != '0.0')
				)
				.map((s) => s.id),
		};
	}, [pipelineQuery.data]);

	const ownerQueryParams = { ownerId: deal.ownerId };
	const { data: owner } = useGetOwnerQuery(ownerQueryParams, {
		skip: !ownerQueryParams.ownerId,
	});

	const stage = useMemo(
		() => stages?.[deal?.dealStage] ?? { label: deal.dealStage, order: 99 },
		[stages]
	);

	const isRenewable = useMemo(
		() =>
			transactions.length > 0 &&
			inactive.includes(deal.dealStage) &&
			(deal.isRenewable == null || deal.isRenewable == true),
		[transactions, deal.isRenewable, deal.dealStage]
	);

	const showInRed = useMemo(
		() => (!deal.renewalDate ? false : isAfter(today, renewalDate)),
		[renewalDate, deal.renewalDate]
	);

	const showInOrange = useMemo(
		() => (!deal.renewalDate ? false : isAfter(today, addDays(renewalDate, -RENEWAL_MAX_DAYS))),
		[renewalDate, deal.renewalDate]
	);

	const status = useMemo(() => {
		if (active.includes(deal.dealStage)) {
			return 'In Progress';
		}
		return transactions.length > 0 ? deal.status ?? 'Not Started' : 'Not Renewable';
	}, [active, deal.dealStage, inactive, transactions]);

	const statusColour = useMemo(() => {
		if (status == 'In Progress') {
			return folioBlue;
		}
		if (showInRed && status == 'Not Started') {
			return '#FF604B';
		}
		if (status == 'Not Started') {
			return '#F28505';
		}
		return 'rgba(0,0,0,0.5)';
	}, [status, showInRed]);

	const handleRenewDeal = () => {
		console.log('RENEW DEAL', deal, deal.clientHubspotId, policies);
		navigate(paths.createRenewal, {
			state: { dealIds: [deal.id], clientId: deal.clientHubspotId, policies },
		});
	};

	const handleOpenDeal = () => {
		const pathName = getUrlFromPipeline(deal.pipeline);
		if (pathName) {
			navigate(`${pathName}/${deal.id}`);
		}
	};

	const [hoveredRow, setHoveredRow] = useState(false);

	const handleMouseEnter = useCallback(() => {
		if (isRenewable && selected.length === 0) {
			setHoveredRow(true);
		}
	}, [isRenewable, selected]);

	const handleMouseLeave = () => setHoveredRow(null);

	const checkboxDisabled =
		!isRenewable || selected.some((s) => s.clientHubspotId != deal.clientHubspotId);

	const checkboxChecked = selected.some((s) => s.id == deal.id);

	return (
		<TableRow
			{...(selected.length === 0 && {
				onClick: handleOpenDeal,
				style: { cursor: 'pointer' },
			})}
			hover
			key={`table-row-${deal.id}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			{...(checkboxChecked && {
				sx: {
					backgroundColor: 'rgba(80, 90, 252, 0.1)',
					'&:hover': { backgroundColor: 'rgba(80, 90, 252, 0.1) !important' },
				},
			})}
		>
			<TableCell>
				<Checkbox
					disabled={checkboxDisabled}
					value={checkboxChecked}
					onClick={(e) => e.stopPropagation()}
					onChange={(e) => {
						dispatch(setSelected({ ...deal, policies }));
					}}
					sx={{
						'&.Mui-disabled': {
							pointerEvents: 'auto',
							// '&:hover': {
							// 	backgroundColor: 'transparent',
							// },
							cursor: 'not-allowed',
						},
					}}
				/>
			</TableCell>
			<TableCell>
				<Stack
					direction='row'
					spacing={3}
					alignItems={'center'}
					justifyContent={'space-between'}
					width='100%'
				>
					{deal.dealName}
					<Fade in={hoveredRow} orientation='horizontal'>
						<Button
							onClick={(e) => {
								e.stopPropagation();
								handleRenewDeal();
							}}
							variant='contained'
							size='small'
						>
							Renew
						</Button>
					</Fade>
				</Stack>
			</TableCell>
			<TableCell>
				<ClientNameAndAvatar
					title={deal.clientName ?? 'Client not found'}
					subtitle={deal.contactName ?? 'Unknown'}
				/>
			</TableCell>
			<TableCell>
				{deal.policiesLoading ? (
					<Skeleton width={'100%'} />
				) : policies.length > 0 ? (
					<Stack>
						{policies.map((policy, i) => (
							<Typography key={`${deal.id}-policy-${i}-${policy.Id}`}>
								<span style={{ marginRight: '0.25em' }}>{policy.ClassOfRisk}</span>
								<span style={{ marginRight: '0.25em' }}>{' - '}</span>
								<span>{policy.PolicyNumber}</span>
							</Typography>
						))}
					</Stack>
				) : (
					<Typography sx={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.5)' }}>
						{'No policies linked'}
					</Typography>
				)}
			</TableCell>
			<TableCell>
				{owner?.firstName
					? `${owner?.firstName ?? '-'} ${owner?.lastName ?? ''}`
					: owner?.email ?? '-'}
			</TableCell>
			<TableCell>
				<Stack direction='row' spacing={1} alignItems={'center'}>
					{getPipelineIcon(deal.pipeline, '30px')}
					<Typography>{pipelineName}</Typography>
				</Stack>
			</TableCell>
			<TableCell align='right'>
				<Typography
					{...((showInRed || showInOrange) && { color: showInRed ? 'error' : 'warning' })}
				>
					{formatDate(deal.renewalDate)}
				</Typography>
			</TableCell>
			<TableCell>
				<Stack direction='row' spacing={1} alignItems={'center'}>
					<BlinkingCircle fontSize='6px' color={statusColour} />
					<ClientNameAndAvatar
						title={
							<span style={{ alignContent: 'center' }}>
								{deal.policiesLoading ? (
									<Skeleton variant='text' />
								) : (
									<Stack direction='row' spacing={'0.25em'} alignItems={'center'}>
										{status}
										{status == 'Not Renewable' && (
											<Tooltip
												title={
													transactions.length == 0
														? 'Please associate a transaction to this deal'
														: 'Deal cannot be renewed'
												}
											>
												<InfoIcon
													sx={{
														fontSize: 14,
														color: 'rgba(0,0,0,0.5)',
													}}
												/>
											</Tooltip>
										)}
									</Stack>
								)}
							</span>
						}
						subtitle={
							<>
								{pipelineQuery.isLoading || pipelineQuery.isUninitialized ? (
									<Skeleton variant='text' />
								) : (
									stage?.label ?? ''
								)}
							</>
						}
					/>
				</Stack>
			</TableCell>
		</TableRow>
	);
};
