import { useCallback } from 'react';
import { storage } from '@/utils/firebase';
import { ref, uploadBytes } from 'firebase/storage';
import { useUpdateDealRowMutation } from '@/features/deals/dealsApi';
import { useUpdateClientRowMutation } from '@/features/clients/clientsApi';
import {
	folderStructure,
	getFolderFromPipeline,
	getFolderStructure,
	getPipelineName,
	replaceFolderName,
} from '@/utils/constants';
import { useSharepoint } from './useSharepoint';
import { useUpdateBrokerMutation } from '@/features/user/userApi';
import { useMsGraph } from './useMsGraph';

export const useClientStorageHooks = (userDetails) => {
	const sharepointSite = userDetails?.sharepointSite;
	const clientSiteSuffix = userDetails?.clientSiteSuffix;
	const clientFolder = userDetails?.clientFolder;
	const clientSite = userDetails?.clientSite;

	// Hooks are called at the top level of the custom hook
	// const [createDealRow] = useCreateDealRowMutation();

	const [updateClientRow] = useUpdateClientRowMutation();
	const [updateBroker] = useUpdateBrokerMutation();
	const [updateDeal] = useUpdateDealRowMutation();

	const {
		getSharepointLists,
		getSharepointListItems,
		getSharepointListItemChildren,
		getDriveItem,
		getSharepointSiteDrives,
		querySharepointSites,
		getClientFolderDrive,
		getClientMsGraphFolders,
		searchClientMsGraphFolders,
		searchClientMsGraphFoldersByItemId,
	} = useMsGraph();

	const { createFolder, addFileToSharepoint } = useSharepoint(
		sharepointSite,
		clientSiteSuffix,
		clientFolder,
		clientSite
	);

	const isValidSharepoint =
		userDetails?.sharepointSite != null &&
		userDetails?.clientSiteSuffix != null &&
		userDetails?.clientFolder != null &&
		userDetails?.clientSite != null;

	const uploadFilesToStorage = async (dealId, noteId, dealFolder, pipelineId, files) => {
		const dealType = getPipelineName(pipelineId);
		for (const file of files) {
			const filePath = `/${dealType}Files/${file.name}`;
			const storageRef = ref(storage, filePath);
			const metadata = {
				customMetadata: {
					'Deal ID': dealId.toString(),
					'Note ID': noteId.toString(),
				},
			};
			await uploadBytes(storageRef, file, metadata);
			if (isValidSharepoint) {
				await addFileToSharepoint(dealFolder.url, file);
			} else {
				console.log('Errror, invalid Sharepoint', userDetails);
			}
		}
	};

	const getClientFolders = (clientName, dealName, dealId, pipelineFolder, date, yearFolder) => {
		// Normalise and remove special characters
		const replacedCompanyName = replaceFolderName(clientName);
		console.log('get client folders year folder: ', yearFolder);

		const { folders, dealFolder } = getFolderStructure(
			replacedCompanyName,
			dealName,
			dealId,
			pipelineFolder,
			date,
			yearFolder
		);
		return { folders, dealFolder };
	};

	const createDealFolders = async (
		dealId,
		dealName,
		clientName,
		pipelineId,
		date,
		yearFolder
	) => {
		const pipelineFolder = getFolderFromPipeline(pipelineId);
		const { folders, dealFolder } = getClientFolders(
			clientName,
			dealName.trim(),
			dealId,
			pipelineFolder,
			date,
			yearFolder
		);

		if (isValidSharepoint) {
			for (var path of folders) {
				await createFolder(path);
			}
			const dealFolderUrl = await createFolder(dealFolder.url);

			const body = {
				dealId,
				dealFolderUrl,
				dealFolderPath: dealFolder.url,
				yearRoot: dealFolder.yearRoot,
				dealName: dealFolder.dealName,
				pipelineFolder: dealFolder.pipelineFolder,
			};

			//add client name here?
			const doc = await updateDeal({
				dealId,
				properties: {
					dealFolderUrl,
					dealFolderPath: dealFolder.url,
					yearRoot: dealFolder.yearRoot,
					pipelineFolder: dealFolder.pipelineFolder,
					clientName: clientName,
				},
			}).unwrap();

			// const dealRowResponseId = await createDealRow(body).unwrap();
			// const docId = dealRowResponseId.doc_id;

			return {
				success: 'Created client folders',
				dealFolder,
				dealFolderUrl,
				documentId: doc.id,
			};
		} else {
			console.log('Could not create client folders, invalid Sharepoint site', userDetails);
			return {
				error: 'Could not create client folders, invalid Sharepoint site',
				userDetails,
			};
		}
	};

	const setUpDealFilesAndFolders = async (
		dealId,
		noteId,
		clientName,
		dealName,
		pipelineId,
		files,
		date,
		yearFolder
	) => {
		const { success, dealFolder, dealFolderUrl, documentId } = await createDealFolders(
			dealId,
			dealName.trim(),
			clientName,
			pipelineId,
			date,
			yearFolder
		);
		await uploadFilesToStorage(dealId, noteId, dealFolder, pipelineId, files);
		return { success, dealFolder, dealFolderUrl, documentId };
	};

	const createClientFolder = useCallback(
		async (dealId, contextValue) => {
			const { deal, client, objectType, yearFolder } = contextValue;
			const date = deal.createdAt;

			console.log('Create client folder hook');

			if (!client || !deal) {
				console.log('Could not create folder: could not locate client/deal', dealId);
				return;
			}

			const dealName = deal.dealname ?? deal.description ?? deal.subject ?? deal.content;
			await setUpDealFilesAndFolders(
				dealId,
				null,
				client?.name,
				dealName.trim(),
				deal.pipeline ?? deal.hs_pipeline,
				[],
				date,
				yearFolder
			);
		},
		[setUpDealFilesAndFolders]
	);

	const getMatchingItem = (list, name) =>
		list.find(
			(listItem) =>
				decodeURI((listItem.name ?? '').normalize()) ==
					decodeURI((name ?? '').normalize()) ||
				(listItem?.webUrl ?? '').toLowerCase().includes(name.toLowerCase())
		);

	const updateClientGraphProperties = useCallback(
		async (clientRow, dealRow) => {
			console.log('USER DETAILS', userDetails);
			if (userDetails && clientRow) {
				const siteQuery = (userDetails?.clientSiteSuffix ?? '').split('/');

				const getSite = async (query) => {
					const siteId = await querySharepointSites(query[query.length - 1]);
					console.log('SITE ID', siteId);
					if (siteId) {
						await updateBroker({
							docId: userDetails?.brokerId,
							properties: { sharepointSiteId: siteId },
						}).unwrap();
					}
				};

				const getSiteFiles = async (siteId) => {
					console.log('GET SITE FILES', siteId);
					const drives = await getSharepointSiteDrives(siteId);
					const drive = getMatchingItem(drives, userDetails?.clientFolder);
					console.log(
						'DRIVES',
						drives,
						'DRIVE',
						drive,
						'userDetails?.clientFolder',
						userDetails?.clientFolder
					);

					const lists = await getSharepointLists(userDetails?.sharepointSiteId);
					const list = getMatchingItem(lists, userDetails?.clientFolder);
					console.log('LISTS', lists, 'LIST', list);
					if (drive != null || list != null) {
						await updateBroker({
							docId: userDetails?.brokerId,
							properties: {
								...(drive && { clientFolderDriveId: drive.id }),
								...(list && { clientFolderListId: list.id }),
							},
						}).unwrap();
					}
				};

				const getFolderDrive = async (driveId) => {
					const replacedCompanyName = replaceFolderName(clientRow?.name);
					// const filterFunction = (list) => {
					// 	return getMatchingItem(list, replacedCompanyName);
					// };
					const drives = await searchClientMsGraphFolders(driveId, replacedCompanyName);
					// const drives = await getClientFolderDrive(driveId, filterFunction);
					const drive = getMatchingItem(drives, replacedCompanyName);

					console.log(
						'CLIENT DRIVES',
						drives,
						'CLIENT DRIVE',
						drive,
						'FOLDER NAME',
						replacedCompanyName
					);
					if (drive) {
						await updateClientRow({
							clientId: clientRow?.hubspotId,
							docId: clientRow?.docId,
							properties: { driveId: drive.id },
						}).unwrap();
					}
				};

				// let dealDrive;
				const getDealFolderDrive = async (driveId, itemId) => {
					const path = (dealRow?.dealFolderPath ?? '').split('/');
					const pathName = dealRow?.dealFolderPath
						? path[path.length - 1]
						: `${dealRow?.dealName} ${dealRow?.dealId}`;
					const drives = await searchClientMsGraphFolders(driveId, dealRow?.dealId);
					console.log('DEAL FOLDER DRIVES', drives);

					const drive = getMatchingItem(drives, pathName);

					console.log('DEAL DRIVE', drive);
					if (drive) {
						// dealDrive = drive;
						await updateDeal({
							dealId: dealRow.dealId,
							docId: dealRow.id,
							properties: { driveId: drive.id },
						}).unwrap();
					}
					// else {
					// 	Promise.all(
					// 		drives
					// 			.filter((d) => (d?.folder?.childCount ?? 0) > 0)
					// 			.map(async (d) => {
					// 				if (!dealDrive) {
					// 					await getDealFolderDrive(driveId, d.id);
					// 				}
					// 			})
					// 	);
					// }
				};

				const getFolderList = async (siteId, listId) => {
					const replacedCompanyName = replaceFolderName(clientRow?.name);
					const filterFunction = (list) => {
						const newList = list.filter((l) => l.fields.ContentType === 'Folder');
						console.log(
							`Filtered list fields ${replacedCompanyName}`,
							newList,
							newList.map((f) => f.fields?.FileLeafRef)
						);
						return list
							.filter((l) => l.fields.ContentType === 'Folder')
							.find(
								(l) =>
									decodeURI(l.fields.FileLeafRef.normalize()) ==
									decodeURI(replacedCompanyName.normalize())
							);
					};
					const lists = await getSharepointListItems(siteId, listId, filterFunction);

					const list = filterFunction(lists);

					console.log('FOLDER LIST', list);
					if (list) {
						await updateClientRow({
							clientId: clientRow?.hubspotId,
							docId: clientRow?.docId,
							properties: { listId: list.id },
						}).unwrap();
					}
				};

				const getDealFolderList = async (siteId, listId) => {
					const path = dealRow?.dealFolderPath.split('/');
					const filterFunction = (list) => {
						const newList = list.filter((l) => l.fields.ContentType === 'Folder');
						console.log(
							`Filtered list fields ${path[path.length - 1]}`,
							newList,
							newList.map((f) => f.fields?.FileLeafRef)
						);
						return list
							.filter((l) => l.fields.ContentType === 'Folder')
							.find(
								(l) =>
									decodeURI(l.fields.FileLeafRef.normalize()) ==
									decodeURI(path[path.length - 1].normalize())
							);
					};
					const lists = await getSharepointListItems(siteId, listId, filterFunction);

					const list = filterFunction(lists);

					console.log('FOLDER LIST', list);
					if (list) {
						await updateDeal({
							dealId: dealRow.dealId,
							docId: dealRow.id,
							property: 'listId',
							value: list.id,
						}).unwrap();
					}
				};

				if (siteQuery.length > 0 && userDetails?.sharepointSiteId == null) {
					getSite(siteQuery);
				} else if (
					userDetails?.sharepointSiteId != null &&
					(userDetails?.clientFolderDriveId == null ||
						userDetails?.clientFolderListId == null)
				) {
					getSiteFiles(userDetails?.sharepointSiteId);
				} else if (userDetails?.clientFolderDriveId != null && clientRow.driveId == null) {
					getFolderDrive(userDetails?.clientFolderDriveId);
				} else if (
					userDetails?.clientFolderDriveId != null &&
					dealRow &&
					dealRow.driveId == null
				) {
					getDealFolderDrive(userDetails?.clientFolderDriveId, clientRow.driveId);
				} else if (userDetails?.clientFolderListId != null && clientRow.listId == null) {
					getFolderList(userDetails?.sharepointSiteId, userDetails?.clientFolderListId);
				} else if (dealRow && dealRow?.listId == null) {
					getDealFolderList(
						userDetails?.sharepointSiteId,
						userDetails?.clientFolderListId
					);
				}
			}
		},
		[userDetails]
	);

	const getClientGraphFolderByItemId = useCallback(
		async (driveId, itemId) => {
			console.log('getClientGraphFolderByItemId driveId', driveId, itemId);
			const folders = [];
			const files = [];
			if (userDetails?.clientFolderDriveId != null && driveId != null) {
				const clientDrives = await getClientMsGraphFolders(driveId, itemId);

				console.log('CLIENT DRIVES', clientDrives);
				for (const drive of clientDrives ?? []) {
					Object.keys(drive).includes('file') ? files.push(drive) : folders.push(drive);
				}
			}
			return { folders, files };
		},
		[userDetails]
	);

	const searchClientGraphFolders = useCallback(
		async (driveId, itemId, searchText = '') => {
			console.log('searchClientGraphFolders driveId', driveId, itemId, searchText);
			const folders = [];
			const files = [];
			if (
				userDetails?.clientFolderDriveId != null &&
				driveId != null &&
				searchText.length > 0
			) {
				const clientDrives = await searchClientMsGraphFoldersByItemId(
					driveId,
					itemId,
					searchText
				);

				console.log('SEARCHED CLIENT DRIVES', clientDrives);
				for (const drive of clientDrives ?? []) {
					Object.keys(drive).includes('file') ? files.push(drive) : folders.push(drive);
				}
			}
			return { folders, files };
		},
		[userDetails]
	);
	// https://cognitivltd.sharepoint.com/sites/SecureHorizons/Client%20Documents/1015am
	const getClientGraphFolders = useCallback(
		async (row, recursive = true) => {
			console.log('getClientGraphFolders row', row);

			const folders = [];
			const files = [];
			const getFolders = async (driveId, itemId) => {
				const clientDrives = await getClientMsGraphFolders(driveId, itemId);
				// console.log('CLIENT DRIVES', clientDrives, driveId, itemId);
				const notes = [
					decodeURI(folderStructure.notes.normalize()),
					decodeURI(folderStructure.clientNotes.normalize()),
				];
				// const filteredDrives = (clientDrives ?? []).filter(
				// 	(d) => !notes.includes(decodeURI((d?.name ?? '').normalize()))
				// );
				const filteredDrives = (clientDrives ?? []).filter((d) => {
					const name = (d?.name ?? '').normalize();
					try {
						return Object.keys(d).includes('file') || !notes.includes(decodeURI(name));
					} catch (err) {
						console.log(`Error decoding ${d.name}`, err);
						return Object.keys(d).includes('file') || !notes.includes(name);
					}
				});
				console.log('FILTERED DRIVES', filteredDrives);
				for (const drive of filteredDrives) {
					Object.keys(drive).includes('file') ? files.push(drive) : folders.push(drive);
					if ((drive.folder?.childCount ?? 0) > 0 && recursive) {
						await getFolders(driveId, drive.id);
					}
				}
			};
			if (userDetails?.clientFolderDriveId != null && row.driveId != null) {
				console.log(
					'HERE I GET THE FOLDERS',
					userDetails?.clientFolderDriveId,
					row.driveId
				);
				await getFolders(userDetails?.clientFolderDriveId, row.driveId);
				console.log('FOLDERS', folders, 'FILES', files);
			}
			return { folders, files };
		},
		[userDetails]
	);

	return {
		createClientFolder,
		createDealFolders,
		uploadFilesToStorage,
		setUpDealFilesAndFolders,
		updateClientGraphProperties,
		getClientGraphFolders,
		getClientGraphFolderByItemId,
		searchClientGraphFolders,
	};
};
