/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material';
import { stableSort, getComparator } from '@/components/table/functions';
import { FolioTableHead } from '@/components/table/FolioTableHead';
import { determineContext } from '@/hooks/determineContext';
import { PolicyRow } from '@/components/tables/PolicyRow';

export const PoliciesTab = () => {
	
    
	const { policies } = determineContext();
	console.log('policy:', policies);
	
	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('CreatedWhen');

	const policyHeadCells = [
		{ id: 'policyNumber', numeric: false, label: 'Policy number', align: 'left', sortable: false },
		{ id: 'renewalDate', numeric: false, label: 'Renewal date', align: 'left', sortable: false },
		{ id: 'description', numeric: false, label: 'Description', align: 'left', sortable: false },
		{ id: 'insurer', numeric: false, label: 'Insurer', align: 'left', sortable: false },
	];

	return (
		<TableContainer>
			<Table
				aria-labelledby="tableTitle"
				size="medium"
				aria-label="enhanced table"
				sx={{ minWidth: 600 }}
			>
				<FolioTableHead
					order={order}
					orderBy={orderBy}
					headCells={policyHeadCells}
				/>
				<TableBody>
					{policies.length > 0 ? (
						stableSort(policies, getComparator(order, orderBy)).map(
							(policy, index) => (
								<PolicyRow
									hideCheckbox={true}
									key={`policy-row-index-${index}-${policy.Id}`}
									policy={policy}
								/>
							)
						)
					) : (
						<TableRow>
							<TableCell colSpan={policyHeadCells.length} align="center">
								<Typography variant="subtitle1">No policies linked</Typography>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
